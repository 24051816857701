@mixin Error(
  $className: 'c-forms',
  $errorBg: red,
  $errorBorder: 1px solid red,
  $errorFocusBorder: dashed 1px red,
  $errorFocusShadow: inset 2px 6px 6px 0 red,
  $color: red,
  $warningIcon:
    "data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.99994 2.15699L18.9928 17.9173C18.9946 17.9206 18.9974 17.927 18.9989 17.937C18.9993 17.9392 18.9995 17.9414 18.9997 17.9437H1.00036C1.00082 17.9383 1.0017 17.9333 1.00283 17.9289C1.00453 17.9223 1.00713 17.9173 1.00713 17.9173L9.99994 2.15699ZM9.24429 1.46353C9.46526 1.07626 9.94055 0.864759 10.3845 1.09871L10.4002 1.10701L10.4741 1.15292L10.4881 1.16303C10.5998 1.24368 10.6896 1.34784 10.7556 1.46353L19.8613 17.4217C20.1099 17.8574 20.0239 18.4726 19.5938 18.7831C19.4544 18.8837 19.2859 18.9437 19.1057 18.9437H0.894222C0.327017 18.9437 0 18.4169 0 17.9522C0 17.7681 0.0462178 17.5836 0.138573 17.4217L9.24429 1.46353Z' fill='%23E7000E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.1906 13.0751C10.1777 13.1704 10.0964 13.2415 10.0002 13.2415C9.90389 13.2415 9.82245 13.1701 9.80986 13.0746L9.0531 7.332C9.0177 7.07422 9 6.8821 9 6.75564C9 6.44922 9.09882 6.20359 9.29646 6.01877C9.4941 5.83394 9.73156 5.74153 10.0088 5.74153C10.2861 5.74153 10.5206 5.83394 10.7124 6.01877C10.9041 6.20359 11 6.4784 11 6.84318C11 6.95992 10.9882 7.12285 10.9646 7.332L10.1906 13.0751ZM10.7072 16.4474C10.512 16.6435 10.2763 16.7415 10 16.7415C9.72372 16.7415 9.48799 16.6435 9.29279 16.4474C9.0976 16.2513 9 16.0145 9 15.737C9 15.4655 9.0976 15.2317 9.29279 15.0356C9.48799 14.8396 9.72372 14.7415 10 14.7415C10.2763 14.7415 10.512 14.8396 10.7072 15.0356C10.9024 15.2317 11 15.4655 11 15.737C11 16.0145 10.9024 16.2513 10.7072 16.4474Z' fill='%23E7000E'/%3E%3C/svg%3E%0A"
) {
  %--is-error {
    background-color: $errorBg;
    border: $errorBorder;

    @include focus-handler();
    &:focus {
      border: $errorFocusBorder;
      box-shadow: $errorFocusShadow;
    }
  }

  %--is-error--has-focus {
    @include focus-style();

    border: $errorFocusBorder;
    box-shadow: $errorFocusShadow;
  }

  .#{$className} {
    &__error {
      background-color: $white;
      border-radius: 5px;
      border: $errorBorder;
      clear: both;
      color: $color;
      float: left;
      margin: -3px 0 20px;
      padding: 10px 30px 10px 40px;
      position: relative;
      animation-duration: 0.6s;
      animation-fill-mode: both;
      animation-name: bounceInDown;
      width: 100%;
      background-image: url($warningIcon);
      background-repeat: no-repeat;
      background-position: 12px 12px;

      &:before {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color;
        content: '';
        display: block;
        height: 0;
        left: 40px;
        position: absolute;
        top: -9px;
        width: 0;
        z-index: 9;
      }

      &:after {
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $white;
        content: '';
        display: block;
        height: 0;
        left: 41px;
        position: absolute;
        top: -8px;
        width: 0;
        z-index: 9;
      }

      &__label {
        text-transform: lowercase;
      }

      @content;
    }
  }

  @keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(0, -40px, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(0, 12px, 0);
    }

    75% {
      transform: translate3d(0, -5px, 0);
    }

    90% {
      transform: translate3d(0, 2px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .EPiServerForms .Form__Element .Form__Element__ValidationError {
    color: $color;
  }
}
