@import '../../platformui/ArticleHeader/styles';
@import '../../platformui/ArticleHeader/subject-icon';

@include ArticleHeader($headingColor: false, $paddingTop: 30px) {
  .c-articleheader {
    &--alert {
      &:before {
        background: url('../../framework/images/alert-red.svg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 70px;
        margin: 50px auto 50px;
        width: 70px;
      }

      .c-articleheader {
        &__inner-container {
          margin-left: auto;
          margin-right: auto;
          width: 280px;
          
        @include breakpoint(medium) {
         width: 620px; 
        }

        }
      }
    }
  }
}
