.regional-container {
  border-left: 4px solid c('sky-base');
  margin: 10px 0px 15px;
  min-height: 60px;
  padding-left: 20px;
  position: relative;

  @include breakpoint(large) {
    padding: 1px 0px 0px 30px;
    margin-left: -30px;
  }

  @media print {
    padding: 0px;
    margin-left: 0;
    border-left: 5px solid #e9e9e9;
    page-break-inside: avoid;
  }

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 10px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background-image: linear-gradient(
      to left,
      rgba(c('grey-500'), 0),
      c('sky-background')
    );
    z-index: -1;
    left: 0px;

    @include breakpoint(large) {
      left: -10px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background-image: linear-gradient(
        to right,
        rgba(c('grey-500'), 0),
        c('sky-background')
      );
    }
  }

  .zoom-container {
    margin-top: 0px;
  }

  &__content {
    z-index: -2;
    padding-top: 60px;
    .c-chapter > p:last-child,
    .c-chapter > div > p:last-child {
      margin-bottom: 10px;
    }

    @include breakpoint(large) {
      padding-top: 0px;
    }

    @media print {
      padding-left: 15px;
    }
  }

  &__region {
    position: absolute;
    left: 0px;
    top: 5px;
    display: flex;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: c('sky-background');
    padding: 5px 10px;
    box-shadow: 0px 0px 4px 0px rgba(c('black'), 0.3);

    @include breakpoint(large) {
      left: auto;
      right: 100%;
      margin-right: 4px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    @media print {
      background-color: #e9e9e9 !important;
      position: static !important;
      border-top: 5px solid #e9e9e9 !important;
      border-radius: 0px 10px 10px 0px !important;
      max-width: 300px;
    }

    span {
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      white-space: nowrap;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('../../framework/images/region-pin.svg');
        background-repeat: no-repeat;
      }

      @media print {
        margin-bottom: 0px;
      }
    }
  }
}
