@import './modal.scss';

.diagnostic-panel {
  &__modal-panel {
    z-index: 0;
  }

  &__modal-open {
    &:after {
      z-index: 1;
    }
  }

  .c-collapsible-panel {
    &__panel {
      //For infobox alignment
      position: relative;
      .diagnostic-panel__modal-open span:before {
        color: c('sky-base');
      }
      &--is-expanded {
        margin-top: 8px;
      }
    }

    &__button {
      @include focus-handler($color: c('black'), $offset: 5px);
    }
  }

  h2.diagnostic-panel__heading {
    padding-bottom: 0;
    margin: 0 0 22px 0;
    font-size: 1rem;
    border-bottom: 0;
  }

  &__code-group {
    margin-bottom: 20px;
    .diagnostic-panel__code-group__heading {
      padding-bottom: 5px;
      margin: 0 0 15px 0;
      font-size: 1rem;
    }

    &__wrapper {
      padding: 0 30px 0 0;
      margin: 0;
      li {
        display: grid;
        grid-template-columns: 60px 10fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        list-style: none;
        margin-bottom: 10px;
      }
    }
  }
}
