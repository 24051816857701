@import '../../platformui/Localnav/styles';

@include Localnav(
  $borderColor: rgba(c('main'), 0.3),
  $headingPadding: 14px 0 10px 0,
  $componentMargin: 0 0 60px,
  $itemColor: c('nav'),
  $borderMarkerWidth: 5px,
  $borderMarkerColor: c('main'),
  $selectedItemFontWeight: normal,
  $selectedItemColor: c('nav'),
  $hasChildrenIconColor: c('warm-grey')
) {
  &__heading {
    font-size: rem-calc(20);

    a {
      color: c('black');
    }
  }

  &__list {
    &__item--is-selected {
      margin-bottom: 1px;
    }
    &__secondary {
      .c-localnav__list__item {
        a {
          padding-left: 40px;

          &:hover {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
