@import '../../platformui/ImageBlock/styles';

@include ImageBlock(
  $descriptionColor: c('stone-dark'),
  $textBg: c('stone-dark'),
  $hoverBg: c('sky-base'),
  $marginBottom: (
    40px,
    40px,
    50px,
  ),
  $zoomBg: c('sky-base'),
  $zoomRight: 10px,
  $zoomCloseBg: c('sky-base'),
  $zoomCloseColor: c('white'),
  $zoomCloseFontSize: 23px,
  $printMargin: 0
) {
  .c-imageblock {
    @media print {
      margin: 0 !important;
      break-inside: avoid;
    }

    &__zoom {
      &__trigger {
        padding-top: 50px;
        &:before {
          right: 16px;
          top: 17px;

          [dir='rtl'] & {
            left: 14px;
            transform: scaleX(-1);
            right: auto;
          }
        }

        &:after {
          width: 30px;
          height: 30px;

          [dir='rtl'] & {
            left: 10px;
            right: auto;
          }
        }

        &:focus {
          outline: none;

          &:after {
            outline: 2px solid c('grey-500');
            outline-offset: 2px;
          }
        }
      }

      &__image__close {
        @include focus-handler($color: c('grey-500'), $offset: 2px);

        [dir='rtl'] & {
          left: 10px;
          right: auto;
        }
      }
    }

    &__image {
      .c-imageblock--is-gallery & {
        @include breakpoint(medium) {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }

    &__text {
      font-size: 0.875rem;
      margin: 8px 0;
      line-height: 1.43;
      width: 100%;

      &:after {
        width: 43px;
      }

      @media print {
        font-size: 8px;
        margin-bottom: 30px !important;

        &:after {
          background-color: $black;
          bottom: -10px;
        }
      }
    }

    &--main-image {
      @include breakpoint(large) {
        margin: 0 0 0 -17.1774%;
      }

      .c-imageblock__text {
        margin: 8px 0 36px;
      }
    }
    &--xsmall,
    &--xsmall-reverse {
      .c-imageblock__text {
        width: 100%;
      }
    }

    &--large {
      .c-chapter & {
        margin-bottom: 40px;
      }
    }
  }
}
