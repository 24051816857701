@mixin SearchList($noHits: transparent, $marginBottom: 150px) {
  .c-searchlist {
    margin-bottom: $marginBottom;

    &__heading {
      font-weight: normal;
      margin: 16px 20px;
    }

    &__footer {
      text-align: center;
      position: relative;
    }

    .c-related {
      transition: opacity 0.3s ease, scale 0.3s ease;
    }

    .is-loading & {
      .c-related {
        opacity: 0.6;
      }
    }

    &__no-hits {
      background: $noHits;
      border-radius: 25px;
      padding: 40px;
    }

    @content;
  }
}

@mixin SearchLegend($className: c-search,
  $fontFamily: inherit,
  $fontWeight: inherit,
  $fontSize: inherit,
  $color: black,
  $letterSpacing: inherit,
  $textTransform: inherit) {
  .#{$className}__legend {
    font-family: $fontFamily;
    font-weight: $fontWeight;
    font-size: $fontSize;
    color: $color;
    letter-spacing: $letterSpacing;
    text-transform: $textTransform;
  }
}

@mixin SearchField($className: c-search,
  $searchFieldLabel: transparent,
  $buttonBg: pink,
  $buttonColor: $black,
  $buttonBorderRadius: 0,
  $buttonWidthLarge: 87px,
  $buttonHoverBg: red,
  $showIconInMobile: false,
  $searchFieldMarginBottom: (rem-calc(10),
    rem-calc(30),
  )) {
  .#{$className}__field {
    height: 50px;
    margin-bottom: nth($searchFieldMarginBottom, 1);
    margin-top: 15px;
    padding-right: 69px;
    position: relative;
    z-index: 2;

    @if ($showIconInMobile==false) {
      .icon-search:before {
        @include breakpoint(small only) {
          content: '';
        }
      }
    }

    @include breakpoint(medium) {
      height: rem-calc(60);
      margin-bottom: nth($searchFieldMarginBottom, 2);
      padding-right: rem-calc(87);
    }

    &__label {
      color: $searchFieldLabel;
      font-size: rem-calc(30);
      left: rem-calc(3);
      position: absolute;
      top: rem-calc(4);

      i {
        display: none;
      }

      @include breakpoint(medium) {
        left: rem-calc(7);
        top: rem-calc(7);

        i {
          display: block;
        }
      }
    }

    &__button {
      background: $buttonBg;
      border-radius: nth($buttonBorderRadius, 1);
      color: $buttonColor;
      cursor: pointer;
      height: 50px;
      position: absolute;
      top: 0;
      right: 0;
      width: 69px;

      @include breakpoint(medium) {
        height: rem-calc(60);
        width: $buttonWidthLarge;
      }

      @include breakpoint(large) {
        border-radius: nth($buttonBorderRadius, 2);
      }

      &:hover,
      &:focus {
        background: $buttonHoverBg;
        outline: none;
      }
    }
  }

  @content;
}

@mixin SearchFieldQuery($className: c-search,
  $searchFieldBg: transparent,
  $cta: #000,
  $muted: #000,
  $border: none,
  $borderColor: #000,
  $borderRadius: (0,
    0,
  ),
  $searchFieldFocusBorder: none,
  $searchFieldFocusShadow: none,
  $paddingLeft: (20px,
    56px,
  )) {
  .#{$className}__field__query {
    background: $searchFieldBg;
    border: $border;
    border-right: none;
    border-radius: nth($borderRadius, 1);
    color: $black;
    height: 50px;
    padding-left: nth($paddingLeft, 1);
    width: 100%;

    @include breakpoint(medium) {
      height: rem-calc(60);
      padding-left: nth($paddingLeft, 2);
    }

    @include breakpoint(medium) {
      border-radius: nth($borderRadius, 2);
    }

    &:focus {
      border: $searchFieldFocusBorder;
      box-shadow: $searchFieldFocusShadow;
      outline: none;
    }

    &:focus-within {
      border: $searchFieldFocusBorder;
      box-shadow: $searchFieldFocusShadow;
      outline: none;
    }
  }

  @content;
}

@mixin SearchAutocomplete($className: 'c-search__autocomplete',
  $panelBg: salmon,
  $panelPadding: 0 0 25px 0,
  $itemHoverBg: aqua,
  $bottomBorderRadius: 0,
  $topBorderRadius: 0,
  $itemFontStyle: italic,
  $itemPadding: 6px 20px 10px) {
  .#{$className} {
    background: $panelBg;
    border-bottom-left-radius: $bottomBorderRadius;
    border-bottom-right-radius: $bottomBorderRadius;
    border-top-left-radius: $topBorderRadius;
    border-top-right-radius: $topBorderRadius;
    left: 20px;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: $panelPadding;
    position: absolute;
    right: 70px;

    @include breakpoint(medium) {
      left: 36px;
      right: 87px;
    }

    li {
      border-left: none;
      margin: 0;
      height: auto;
      padding: 0;
      width: 100%;
    }

    &__item {
      a {
        display: block;
        font-style: $itemFontStyle;
        padding: $itemPadding;

        &:hover {
          background: $itemHoverBg;
          text-decoration: none;
        }
      }

      &--is-selected {
        a {
          background: $itemHoverBg;
        }
      }
    }

    @content;
  }
}

@mixin QuickSearch($className: 'c-search__quicksearch',
  $panelBg: white,
  $itemHoverBg: transparent,
  $borderColor: black,
  $labelFontColor: black,
  $linkColor: blue) {
  .#{$className} {
    background-color: $panelBg;
    position: fixed;
    left: 0;
    top: 80px;
    overflow: hidden;
    padding: 30px;
    width: 100%;

    @include breakpoint(large) {
      position: absolute;
      top: 60px;
      width: calc(100% - 130px);
    }

    li {
      height: unset;

      a {
        display: block;
        font-style: italic;
        font-size: 1rem;
        color: $linkColor;
        text-decoration: none;

        &:hover {
          background: $itemHoverBg;
          text-decoration: none;
        }
      }
    }

    &__autocomplete {
      display: flex;
      flex-flow: row wrap;
      gap: 5px;
      width: 100%;
      padding: 0 0 10px 0;
      margin-bottom: 30px;
      border-bottom: 1px solid $borderColor;
      list-style: none;

      @include breakpoint(large) {
        li>a {
          margin-right: 30px;
        }
      }

      &__label {
        display: block;
        width: 100%;
        line-height: 24px;
        color: $labelFontColor;

        @include breakpoint(large) {
          margin-right: 20px;
          width: unset;
        }
      }
    }

    &__search-hits {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      list-style: none;

      &__label {
        font-size: 0.875rem;
        letter-spacing: 1.4px;
        font-weight: normal;
        margin: 0;
        padding-bottom: 15px;
        text-transform: uppercase;
        width: 100%;
        line-height: 24px;
        color: $labelFontColor;
        border-bottom: 1px solid $borderColor;
      }

      li {
        border-bottom: 1px solid $borderColor;
        display: flex;
        padding-block: 10px;
        flex-flow: column wrap;

        @include breakpoint(large) {
          flex-flow: row wrap;
        }

        a {
          font-style: normal;
          line-height: 24px;
          width: fit-content;

          @include breakpoint(large) {
            display: flex;
          }
        }
      }

      &__heading {
        display: block;
        color: $linkColor;
        font-weight: 700;

        @include breakpoint(large) {
          font-weight: 400;
        }
      }

      &__information-type {
        display: block;
        font-size: 0.875rem;
        color: $labelFontColor;
      }
    }

    @content;
  }
}

@mixin SearchTools($borderColor: $black, $borderOpacity: 0.2) {
  .c-search__tools {
    border-bottom: solid 1px rgba($borderColor, $borderOpacity);
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 10px;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    @include breakpoint(medium) {
      border-bottom: solid 1px rgba($borderColor, $borderOpacity);
      border-top: solid 1px rgba($borderColor, $borderOpacity);
      margin-bottom: 40px;
    }

    .c-searchlist__heading {
      text-align: center;

      @include breakpoint(medium) {
        text-align: left;
      }
    }
  }

  @content;
}

.c-search {
  .c-alert {
    margin-top: 0;

    &__icon-container {
      margin-right: 12px;

      @include breakpoint(medium) {
        margin: 12px;
      }

      @include breakpoint(large) {
        margin: 0px;
      }
    }
  }
}
