@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNavWrapper($bgColor: $white);

@include MobileNav($isSelectedBorderLeft: c('main'),
  $fontSize: 1rem,
  $listItemColor: c('main')) {
  font-weight: bold;
  @include secondary-font-family();

  @include Chevron($className: c-expand-button, $chevronColor: c('main')) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 10px;
    width: 50px;
    @include focus-handler($offset: -8px);
  }

  .c-mobilenav__list__item {
    box-shadow: none;

    a {
      border-bottom: 1px solid c('stone-line');
    }

    &--is-open>.c-mobilenav__list__item__container>a {
      border-left: 6px solid c('main');
    }
  }
}

@include MobileNavSubLevel($hasBorder: false,
  $bgColor: c('white'),
  $borderOnHoverColor: c('secondary-light'),
  $listItemBorderLeftWidth: 7px,
  $fontSize: 1rem,
  $textColor: black,
  $sublevelBoxShadow: none) {
  .c-mobilenav {
    &__list__item {
      box-shadow: none;

      &__container {
        box-shadow: 7px 0px 0px c('secondary-light') inset;
      }

      a {
        padding-left: 33px;
        @include focus-handler($offset: -2px);
        font-size: 1rem;
        font-weight: normal;
        color: c('nav');
      }
    }

    &--selected-item>.c-mobilenav__list__item__container {
      border-bottom: 1px solid c('secondary-light');
      background-color: c('sky-background');
    }
  }

  @include Chevron($className: c-expand-button, $chevronColor: c('main')) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    right: 10px;
    width: 50px;
    @include focus-handler($offset: -8px);
  }

  @include SubLevelIndent($textIndent: 53px) {
    // sublevel 2

    @include SubLevelIndent($textIndent: 73px) {
      // sublevel 3
      @include SubLevelIndent($textIndent: 90px) // sublevel 4
      ;
    }
  }
}
