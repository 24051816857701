@import '../../platformui/Facets/filterbar-facets.scss';

@include FilterBarFacets(
  $itemColor: c('sky-base'),
  $itemSelectedColor: c('stone-dark'),
  $selectedItemBorder: 7px solid c('sky-base')
) {
  .c-facets {
    $outline: 2px solid c('stone-dark');
    &__inner {
      border-bottom: 1px solid c('sky-line');
    }

    &__item {
      &:hover,
      &:focus {
        outline: $outline;
      }
      &:focus-within {
        outline: $outline;
      }
    }
  }
} ;
