@mixin Chapter(
  $h2Color: pink,
  $h3h4Color: brown,
  $collectionPageTextWidth: 720px
) {
  .c-chapter,
  .c-chapter-container,
  .c-chapter-container-collection {
    clear: both;

    h2 {
      @include breakpoint(large) {
        color: $h2Color;
      }
    }

    .c-regionalization__heading {
      color: $h3h4Color;
      display: block;
    }

    h3,
    h4 {
      color: $h3h4Color;
    }

    h2,
    h3,
    p,
    ul,
    ol {
      max-width: $collectionPageTextWidth;
    }

    &--inline-image {
      p {
        max-width: rem-calc(1048);
      }
    }

    &__img-container {
      &--two-col {
        display: flex;
        flex-wrap: wrap;
        & div {
          flex: 0 0 50%;
          padding: 0px 5px 0 0;
        }
      }
      &--three-col {
        display: flex;
        flex-wrap: wrap;
        & div {
          flex: 0 0 50%;
          padding: 0px 5px 0 0;
          @include breakpoint(medium) {
            flex: 0 0 33.333333%;
          }
        }
      }
    }
  }

  .c-chapter-container-collection {
    margin-inline: auto;
    margin-block: rem-calc(20);
    @include breakpoint(medium) {
      max-width: calc(2 * 100% / 3);
    }

    @include breakpoint(large) {
      max-width: rem-calc(1048);
      margin-block: rem-calc(50);
    }
  }

  .toggle-chapter {
    summary {
      list-style-type: none;
      &::-webkit-details-marker {
        display: none;
      }
    }
  }

  @content;
}
