@import '../../platformui/Chapter/styles';
@import './toggle-chapter.scss';

@include Chapter($h2Color: c('stone-dark'), $h3h4Color: c('stone-dark')) {
  .c-chapter {
    @include clearfix();

    .c-imageblock--large {
      margin-top: 16px;
    }

    &__heading {
      &--is-anchornav {
        display: none;

        @include breakpoint(large) {
          display: inline-block;
        }
      }

      display: inline-block;
    }

    a {
      @include focus-handler($color: c('grey-500'), $offset: 2px);
    }
  }
}
