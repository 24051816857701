@import '../Linklist/styles.scss';

@mixin SubjectIntroLinklist($linkColor: yellow, $hoverColor: pink) {
  .subject-intro-linklist,
  .subject-intro-linklist-vardpersonal {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-right: 10px;
    }

    &__link {
      align-items: center;
      background-color: $linkColor;
      background-image: none;
      border-bottom: none;
      border-radius: 30px;
      cursor: pointer;
      display: inline-block;
      font-family: $secondary-font;
      font-size: rem-calc(20);
      font-weight: 500;
      justify-content: center;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.1s ease-in;
      @include focus-handler();

      @include breakpoint(medium) {
        margin: unset;
      }

      &:hover {
        background-color: $hoverColor;
      }

      &:before {
        content: none;
      }

      span {
        border-bottom: none;
        color: $white;
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: 700;
        line-height: 1.4;
        padding: 10px 0;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
  @content;
}

@include Linklist($className: 'subject-intro-linklist') {
  @include SubjectIntroLinklist();
}

@include Linklist($className: 'subject-intro-linklist-vardpersonal') {
  @include SubjectIntroLinklist();
}
