@mixin GlobalSearch($showIconInMobile: true) {
  .c-globalsearch {
    @if ($showIconInMobile == false) {
      .icon-search:before {
        @include breakpoint(small only) {
          content: none;
        }
      }
    }
    &__form-component {
      padding-right: 60px;
      position: relative;

      @include breakpoint(large) {
        padding-right: 87px;
      }
    }

    &__field-container {
      width: 100%;
    }
  }
  @content;
}
