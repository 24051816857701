@mixin ArticleFooter($background: brown, $margin: 0, $padding: 30px 0) {
  .c-articlefooter {
    margin: $margin;
    padding: $padding;
    background: $background;
    float: left;
    width: 100%;

    &__content {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .c-collapsible-panel__workgroup.c-collapsible-panel {
        border: none;

        &__panel {
          background-color: unset;
          padding-left: 0;
        }
        &__button {
          &:focus {
            outline-offset: 2px;
          }
          &__inner {
            font-size: 1rem;
            padding: 0;
            text-transform: unset;
            letter-spacing: unset;
            justify-content: flex-start;
          }

          &__icon,
          &__icon-container {
            height: 20px;
            width: 20px;
          }
          &__icon {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='19.5' y='0.5' width='19' height='19' rx='9.5' transform='rotate(90 19.5 0.5)' fill='%23F1F1F5'/%3E%3Crect x='19.5' y='0.5' width='19' height='19' rx='9.5' transform='rotate(90 19.5 0.5)' stroke='%23396291'/%3E%3Cmask id='mask0_6117_11493' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='5' y='6' width='10' height='10'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4533 13.4533C10.2029 13.7036 9.79706 13.7036 9.54673 13.4533L5.18775 9.0943C4.93742 8.84396 4.93742 8.43809 5.18775 8.18775C5.43809 7.93742 5.84396 7.93742 6.0943 8.18775L10 12.0935L13.9057 8.18775C14.156 7.93742 14.5619 7.93742 14.8122 8.18775C15.0626 8.43809 15.0626 8.84396 14.8122 9.0943L10.4533 13.4533Z' fill='%23396291'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_6117_11493)'%3E%3Crect width='10' height='10' transform='matrix(0 1 -1 0 15 7)' fill='%23396291'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 20px 20px;
            left: 5px;
            &--is-expanded {
              transform: rotate(-180deg);
              top: 2px;
            }
            &:before {
              content: none;
            }
            &:after {
              content: none;
            }
          }
        }
      }
    }

    &__key {
      display: block;
      margin-bottom: 5px;
    }

    &__value {
      display: block;
      margin-bottom: 10px;
    }

    &__info-text > p {
      font-size: 1rem;
      font-style: italic;
      line-height: 1.5;
      margin: 10px 0 0 0;
    }

    .row {
      max-width: 480px;

      @include breakpoint(medium) {
        max-width: 80rem;
      }
    }
  }

  @content;
}
