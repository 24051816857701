@mixin TableWrapper($scrollbarColor: transparent) {
  .table-wrapper {
    margin-bottom: 30px;

    @include breakpoint(large) {
      margin-bottom: 50px;
    }

    &-inner {
      .regional-container__content & {
        padding: 20px;
        background-color: $white;
      }
    }

    // table is wide, dependent on wide-class set in Epi
    // fe-logic in platformui/Chapter/index.js
    &--is-wide {
      @include breakpoint(large) {
        width: calc(100% + 100px);
      }

      @include breakpoint(1300px) {
        width: calc(100% + 200px);
      }

      @media print {
        width: 100%;
      }

      .table-wrapper-inner {
        @include breakpoint(large) {
          margin-left: -100px;
          margin-right: 100px;
        }

        @include breakpoint(1300px) {
          margin-left: -200px;
          margin-right: 200px;
        }

        @media print {
          margin: 0;
        }
      }
    }

    // Table has overflow, faded edges right and left
    // fe-logic in platformui/Chapter/index.js
    &--is-overflow {
      position: relative;

      table {
        padding: 0 10px;

        @include breakpoint(large) {
          padding: 0 30px;
        }

        @media print {
          padding: 0;
        }
      }

      caption {
        margin: 0 10px;

        @include breakpoint(large) {
          margin: 0 30px;
        }

        @media print {
          margin: 0;
        }
      }

      // class controlling overflow
      .table-wrapper-inner {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @media print {
          overflow-x: visible !important;
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 14px;
          height: 14px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 8px;
          border: 3px solid transparent;
          background: $scrollbarColor;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          margin: 0 10px;

          @include breakpoint(large) {
            margin: 0 30px;
          }
        }
      }

      &:before {
        position: absolute;
        content: '';
        height: 100%;
        width: 15px;
        background-image: linear-gradient(to right, rgba($white, 0), $white);
        top: 0;
        right: 0;
        z-index: 12;

        @include breakpoint(large) {
          width: 30px;
        }
      }

      &:after {
        position: absolute;
        content: '';
        height: 100%;
        width: 15px;
        background-image: linear-gradient(to left, rgba($white, 0), $white);
        top: 0;
        left: 0;

        @include breakpoint(large) {
          width: 30px;
        }
      }

      &:after,
      &:before {
        @media print {
          content: none;
        }
      }

      // Table is both wide and has overflow
      &.table-wrapper--is-wide {
        @include breakpoint(large) {
          &:before {
            right: 100px;
          }

          &:after {
            left: -100px;
          }
        }

        @include breakpoint(1300px) {
          &:before {
            right: 200px;
          }

          &:after {
            left: -200px;
          }
        }
      }
    }
    @content;
  }
}
