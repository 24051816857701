@media print {
  $columnSizes: small medium large;
  @for $i from 1 through 12 {
    @each $size in $columnSizes {
      .#{$size}-#{$i} {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  $columnSizesPull: 'small-pull' 'medium-pull' 'large-pull';
  @for $i from 1 through 12 {
    @each $size in $columnSizesPull {
      .#{$size}-#{$i} {
        left: 0 !important;
      }
    }
  }

  $columnSizesPush: 'small-push' 'medium-push' 'large-push';
  @for $i from 1 through 12 {
    @each $size in $columnSizesPush {
      .#{$size}-#{$i} {
        left: 0 !important;
      }
    }
  }

  .row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .columns,
  .column {
    [dir='rtl'] & {
      float: left !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    color: #000 !important;
  }

  h1 {
    font-size: 26px;
  }

  h2,
  h3,
  h4,
  h5,
  blockquote {
    font-size: 14px !important;
  }

  blockquote {
    width: 100% !important;
    padding-left: 0 !important;
  }

  p,
  li,
  a,
  span {
    font-size: 12px !important;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  img,
  table,
  ul,
  ol {
    page-break-inside: avoid;
  }

  img {
    width: auto !important;
    max-height: 400px !important;
  }

  ol,
  ul {
    li {
      padding-left: 0 !important;
      margin-bottom: 6px !important;
    }
  }

  ul {
    list-style: disc !important;

    li {
      display: list-item !important;
    }
  }

  .cookie-panel,
  .c-alert,
  .c-buildinfo {
    display: none !important;
  }
}

.print-only {
  display: none;

  @media print {
    display: block;
  }
}

.hide-for-print {
  @media print {
    display: none;
  }
}
