@mixin FullscreenModal(
  $className: 'fullscreen-modal',
  $open: '.#{$className}__modal-open--open',
  $cta: #000,
  $muted: #000,
  $linkHover: #000,
  $white: #fff,
  $black: #000
) {
  .#{$className} {
    &__modal-open {
      background: transparent;
      color: $cta;
      cursor: pointer;
      @include base-font-family();
      font-size: rem-calc(16);
      height: 22px;
      line-height: 24px;
      overflow: visible;
      padding: 0 0 0 22px;
      position: relative;

      @include breakpoint(large) {
        padding: 0 0 0 28px;
      }

      &:focus {
        @include focus-handler($color: $muted, $offset: 4px);
      }

      span {
        text-decoration: underline;

        &:hover {
          color: $linkHover;
        }
      }
    }

    &__modal-close {
      background-color: $white;
      border-radius: 100%;
      box-shadow: 0 0 6px 0 rgba($black, 0.3);
      cursor: pointer;
      left: calc(100% - 45px);
      height: 40px;
      position: absolute;
      top: 6px;
      width: 40px;
      z-index: 2;
      @include icon('e84e');
      @include focus-handler($color: $muted, $offset: 2px);

      @include breakpoint(large) {
        left: calc(100% - 103px);
        top: 68px;
      }

      &:before {
        color: $linkHover;
        font-size: 30px;
        left: 5px;
        margin: 0;
        position: absolute;
        top: 5px;
      }
    }

    &__modal-panel {
      background-color: rgba($black, 0.4);
      bottom: 0;
      height: 100%;
      left: 0;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 3000;

      &__content {
        background-color: #fefefe;
        border: 1px solid #888;
        border-radius: 10px;
        height: auto;
        margin: 10px;
        padding: 20px;
        width: calc(100% - 20px);

        @include breakpoint(large) {
          margin: 75px auto;
          width: calc(100% - 150px);
        }
      }
    }
    @content;
  }
}

.modal-is-closed {
  display: none;
}
