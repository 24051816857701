@import '../../../platformui/TableWrapper/styles';

table {
  border-collapse: separate;
  border-spacing: 0;
  clear: both;
  margin-bottom: 30px;
  min-width: 100%;

  @media print {
    border-top: 3px solid c('black');
  }

  .table-wrapper & {
    margin-bottom: 10px;
  }

  caption {
    color: c('stone-dark');
    font-size: 0.875rem;
    margin-bottom: 10px;
    letter-spacing: 1.4px;
    text-align: left;
    text-transform: uppercase;

    [dir='rtl'] & {
      text-align: right;
    }
  }

  thead,
  tbody {
    td,
    th {
      border-bottom: 1px solid c('sky-dark');
      border-right: 1px solid c('sky-dark');
      vertical-align: top;
      @media print {
        border-bottom: 2px solid c('black');
        border-right: 2px solid c('black');
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  thead th {
    @include secondary-font-family();
    background: c('sky-background');
    font-weight: bold;
    white-space: nowrap;

    @media print {
      background-color: transparent;
      border-bottom: 3px solid c('black');
    }

    &:first-child {
      border-top-left-radius: 10px;

      [dir='rtl'] & {
        border-top-left-radius: 0;
        border-top-right-radius: 10px;
      }
    }

    &:last-child {
      border-top-right-radius: 10px;

      [dir='rtl'] & {
        border-top-right-radius: 0;
        border-top-left-radius: 10px;
      }
    }
  }

  tbody {
    tr {
      &:last-of-type {
        th {
          @media print {
            border-bottom: 3px solid c('black');
          }
        }
        td {
          @media print {
            border-bottom: 3px solid c('black');
          }
        }
      }

      td {
        padding: 7px 20px;
      }
    }
  }

  tfoot {
    @media print {
      &:after {
        background-color: c('black');
        content: '';
        height: 1px;
        position: absolute;
        width: 50px;
      }
    }
    td {
      font-size: 0.875rem;
      padding: 7px 0 10px;
      position: relative;
      color: rgba(c('black'), 0.6);

      p {
        font-size: inherit;
      }
    }
  }

  th {
    background: c('sky-background');
    font-weight: bold;
    padding: 10px 20px;
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }
  }

  &.inline {
    min-width: 0;
  }
  &.wide {
    background: c('white');

    @if $editor == 'true' {
      editmenuname: Utfallande tabell;
    }
  }
}

.table {
  &__heading {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: normal;
  }

  &__description,
  &__footer {
    font-style: italic;
  }

  &-heading {
    font-size: 1rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 15px;
  }

  &-overflow {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 40px;

    @media print {
      overflow-x: visible !important;
    }

    &::-webkit-scrollbar {
      background-color: c('sky-background');
      -webkit-appearance: none;
      width: 14px;
      height: 18px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 3px solid transparent;
      background: c('sky-base');
      background-clip: content-box;
    }
  }
}

@include TableWrapper($scrollbarColor: c('main'));
