.process-step__container {
  .togglecontent-container {
    background-color: c('white');

    .plus--subheading {
      position: relative;
      margin-left: auto;
      min-height: 30px;
      min-width: 30px;
    }

    &[open] {
      & > .togglecontent-container__summary {
        .plus--subheading {
          &:before {
            transform: rotate(90deg);
          }
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__summary {
      min-height: auto;
    }

    &--level-1 {
      padding: 10px;
      border-radius: 5px;
      background-color: c('sky-background');
    }

    &--level-2 {
      border: 1px solid c('denim');
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 10px;
    }

    &--level-3 {
      border: none;
      .togglecontent-container {
        &__summary {
          padding: 15px 0px;
          padding-left: 0;
        }
        &:before {
          content: none;
        }
      }
    }

    &--level-4 {
      padding-left: 30px;
    }

    &--level-5 {
      padding-left: 45px;
    }
  }
}
