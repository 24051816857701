@mixin ChevronOpen() {
  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

@mixin Chevron(
  $className: chevron,
  $chevronColor: $black,
  $lineWidth: 2px,
  $lineLength: 11px,
  $borderRadius: 0,
  $leftLinePosition: calc(100% - 28px),
  $rightLinePosition: calc(100% - 21px),
  $top: 22px,
  $height: 55px,
  $width: 40px
) {
  .#{$className} {
    width: $width;
    height: $height;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: $top;
      left: $leftLinePosition;
      height: $lineLength;
      width: $lineWidth;
      border-radius: $borderRadius;
      background-color: $chevronColor;
      transform: rotate(135deg);
      transition: transform 0.2s ease-in;
    }

    &:after {
      content: '';
      position: absolute;
      top: $top;
      left: $rightLinePosition;
      height: $lineLength;
      width: $lineWidth;
      border-radius: $borderRadius;
      background: $chevronColor;
      transform: rotate(-135deg);
      transition: transform 0.2s ease-in, left 0.2s ease-in;
    }

    &--is-open,
    &--is-expanded {
      @include ChevronOpen();
    }

    @content;
  }
}
