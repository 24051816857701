@mixin ArticleHeader($headingColor: black, $paddingTop: 25px) {
  .c-articleheader {
    padding-top: $paddingTop;
    position: relative;

    &.c-articleheader--not-visible {
      padding-top: 30px;
    }

    .c-section & {
      padding-top: 0;

      [dir='rtl'] & {
        float: right;
      }

      &.c-articleheader--has-regionlabel {
        @include breakpoint(large) {
          padding-top: 70px;
        }
      }
    }

    &--maxwidth {
      max-width: 580px;
    }

    &__inner-container {
      .c-articleheader--not-visible & {
        display: none;
      }
      .c-articletags__container {
        .article-tag {
          display: inline-block;
          background-color: #f1f1f5;
          margin: 0 10px 0 0;
          padding: 5px 10px 5px 10px;
          font-size: rem-calc(14);
          border-radius: 4px;
          height: 30px;
        }
      }

      .c-articleheader__video-wrapper {
        &.isAboveTitle {
          margin-top: 30px;
          margin-bottom: -20px;
        }

        @include breakpoint(large) {
          &.isAboveTitle {
            margin-top: 15px;
          }
        }
      }
    }

    &--center {
      text-align: center;
    }

    &__heading {
      overflow-wrap: anywhere;
      @if $headingColor != false {
        color: $headingColor;
      }
    }
  }
  @content;
}
