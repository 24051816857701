@mixin CookiePanel(
  $backgroundColor: #484848,
  $backgroundImage:
    linear-gradient(to bottom, rgba($backgroundColor, 1), rgba($black, 0.4)),
  $buttonBorderRadius: 0,
  $panelAcceptButtonBgColor: transparent,
  $panelAcceptButtonColor: #fff,
  $panelMaxWidth: 1048px,
  $panelBoxShadow: 0 0 10px 0 rgba(0, 0, 0, 0.5),
  $panelContentMinHeight: 126px,
  $panelContentPadding: (
    32px,
    32px,
    32px,
  ),
  $panelContentTextColor: #fff,
  $panelContentFontSize: rem-calc(20),
  $panelContentFontWeight: normal,
  $panelContentLinkTextDecoration: underline,
  $panelAcceptButtonFontSize: false,
  $panelAcceptButtonTextTransform: false,
  $panelAcceptButtonFontWeight: (
    normal,
    normal,
    normal,
  ),
  $panelAcceptButtonHeight: (
    40px,
    50px,
    50px,
  ),
  $panelAcceptButtonMinWidth: (
    140px,
    140px,
    140px,
  )
) {
  .cookie-panel {
    box-shadow: $panelBoxShadow;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: $panelMaxWidth;
    background-blend-mode: normal, multiply;
    background-color: $backgroundColor;
    @if $backgroundImage != none {
      background-image: $backgroundImage;
    }
    z-index: 10000;
    transition: transform 0.3s ease-in-out;
    opacity: 1;
    width: 100%;

    &--is-closed {
      transform: translateY(130%);
    }

    &__content {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: nth($panelContentPadding, 1);
      max-width: 1048px;
      min-height: $panelContentMinHeight;

      @include breakpoint(medium) {
        padding: nth($panelContentPadding, 2);
      }

      @include breakpoint(large) {
        padding: nth($panelContentPadding, 3);
        flex-direction: row;
        justify-content: space-around;
      }

      &__text {
        width: 100%;
        color: $panelContentTextColor;
        margin-bottom: 20px;
        @include breakpoint(large) {
          width: 65%;
          margin-bottom: 0;
        }

        p {
          margin-bottom: 0;
          font-weight: $panelContentFontWeight;

          @if (length($panelContentFontSize) > 1) {
            font-size: nth($panelContentFontSize, 1);

            @include breakpoint(medium) {
              font-size: nth($panelContentFontSize, 2);
            }

            @include breakpoint(large) {
              font-size: nth($panelContentFontSize, 3);
            }
          }
        }

        a {
          color: $panelContentTextColor;
          text-decoration: $panelContentLinkTextDecoration;
        }
      }
    }

    &__button {
      background-color: $panelAcceptButtonBgColor;
      color: $panelAcceptButtonColor;
      border: 1px solid $panelContentTextColor;
      height: nth($panelAcceptButtonHeight, 1);
      min-width: nth($panelAcceptButtonMinWidth, 1);
      padding: 0 22px;
      font-weight: nth($panelAcceptButtonFontWeight, 1);
      border-radius: $buttonBorderRadius;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;

      @if ($panelAcceptButtonFontSize != false) {
        font-size: nth($panelAcceptButtonFontSize, 1);
      }

      @include breakpoint(medium) {
        font-weight: nth($panelAcceptButtonFontWeight, 2);
        height: nth($panelAcceptButtonHeight, 2);
        min-width: nth($panelAcceptButtonMinWidth, 2);
        @if ($panelAcceptButtonFontSize != false) {
          font-size: nth($panelAcceptButtonFontSize, 2);
        }
      }

      @include breakpoint(large) {
        font-weight: nth($panelAcceptButtonFontWeight, 3);
        height: nth($panelAcceptButtonHeight, 3);
        min-width: nth($panelAcceptButtonMinWidth, 3);
        @if ($panelAcceptButtonFontSize != false) {
          font-size: nth($panelAcceptButtonFontSize, 3);
        }
      }

      @if ($panelAcceptButtonTextTransform != false) {
        text-transform: $panelAcceptButtonTextTransform;
      }

      &:hover {
        @if ($panelAcceptButtonBgColor != none) {
          background-color: lighten($panelAcceptButtonBgColor, 0.8);
        }
      }
    }

    @content;
  }
}
