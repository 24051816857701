@mixin ScrollToTop(
  $className: 'c-scroll-to-top',
  $bg: transparent,
  $bgImage: none,
  $height: 40px,
  $width: 40px,
  $focusColor: hotpink
) {
  .#{$className} {
    background: transparent;
    background-image: $bgImage;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 30px;
    display: block;
    height: $height;
    position: fixed;
    right: 20px;
    opacity: 0;
    width: $width;
    z-index: 10000;

    [dir='rtl'] & {
      left: 20px;
      right: auto;
    }

    @include focus-handler($focusColor, 0px);

    &--visible {
      opacity: 1;
    }

    &--footer-is-visible {
      position: absolute;
      bottom: 5px;
    }

    @media print {
      display: none;
      visibility: hidden;
    }

    @content;
  }
}
