.c-chapter-container {
  &--is-nav {
    h2 {  
      display: none;

      @include breakpoint(large) {
        display: block;
      }
    }
    div h2 {
      display: none;
       @include breakpoint(large){
        display: block;
       }
    }
    .c-highlight {
      h2,
      h3 {
        display: block;
      }
    }
  }
}
