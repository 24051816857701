@mixin Select(
  $className: 'c-forms',
  $bg: rgba(#ff00ff, 0.2),
  $border: solid 1px transparent,
  $borderRadius: 25px,
  $focusBorder: solid 1px rgba(#ff00ff, 0.4),
  $focusShadow: inset 2px 6px 6px 0 rgba(#ff00ff, 0.15),
  $disabledBg: rgba(#000, 0.03),
  $disabledBorder: solid 1px rgba(#000, 0.2),
  $color: black,
  $hoverColor: white,
  $iconColor: black,
  $useFade: true,
  $optionHoverBg: #ff00ff,
  $optionHoverBgDisabled: #000,
  $scrollBarBg: #ff00ff,
  $scrollBarBorder: 3px solid #ff00ff,
  $scrollBarBorderRadius: 8px,
  $fadeGradient:
    linear-gradient(to right, rgba(221, 239, 247, 0), rgba(221, 239, 247, 1)),
  $fadeGradientDisabled:
    linear-gradient(to right, rgba(221, 239, 247, 0), rgba(221, 239, 247, 1)),
  $fadeGradientError:
    linear-gradient(to right, rgba(254, 244, 243, 0), rgba(254, 244, 243, 1))
) {
  .#{$className} {
    &__select {
      border: $border;
      border-radius: $borderRadius;
      height: 50px;
      margin-bottom: 20px;
      overflow: hidden;
      position: relative;

      @include icon('f107');

      &:before {
        color: $iconColor;
        font-size: 20px;
        margin: 0;
        position: absolute;
        right: 20px;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        top: 50%;
      }

      @if ($useFade == true) {
        &:after {
          position: absolute;
          top: 14px;
          right: 39px;
          content: '';
          background: $fadeGradient;
          height: 20px;
          width: 20px;
        }
      }

      select {
        background: transparent;
        border: none;
        color: $color;
        height: 50px;
        left: 0;
        padding: 0 40px 0 20px;
        overflow-wrap: anywhere;
        position: absolute;
        width: 100%;
        /*width:calc(100% + 25px);*/
        -webkit-appearance: none;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: calc(100% + 60px);
        }

        &::-ms-value {
          background: none; /* remove blue background on ie10/ie11 when selected*/
          color: $iconColor;
        }

        &:focus {
          outline: none;
        }
      }

      background: $bg;

      &--has-focus {
        @include focus-style();

        border: $focusBorder;
        box-shadow: $focusShadow;
      }

      &--is-disabled {
        background-color: $disabledBg;
        border: $disabledBorder;

        @if ($useFade == true) {
          &:after {
            background: $fadeGradientDisabled;
          }
        }
      }

      &--is-multiple {
        height: auto;
        max-height: 140px;
        padding: 15px 10px;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }

        select {
          height: auto;
          max-height: 110px;
          padding: 0;
          position: relative;
          width: 100%;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 14px;
            height: 14px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: $scrollBarBorderRadius;
            border: $scrollBarBorder;
            background-color: $scrollBarBg;
          }

          option {
            color: $color;
            font-size: rem-calc(16);
            padding: 4px 10px;
            margin-bottom: 2px;

            &:before {
              content: '- ';
            }

            &[disabled] {
              color: $color;
              padding-bottom: 0;
              padding-top: 0;
              &:before {
                content: '';
              }
              &:after {
                content: '– – – – – – – – – – – – – –';
                color: $color;
                display: block;
                font-weight: normal;
                letter-spacing: -1px;
              }
            }

            &:hover,
            &:focus,
            &:active,
            &:checked,
            &[selected] {
              background: linear-gradient($optionHoverBg, $optionHoverBg);
              background-color: $optionHoverBg !important; /* for IE */
              border-radius: 10px;
              color: $hoverColor;

              &[disabled] {
                background: linear-gradient(
                  $optionHoverBgDisabled,
                  $optionHoverBgDisabled
                );
                background-color: $optionHoverBgDisabled !important; /* for IE */
                text-align: left;
                color: $color;

                &:after {
                  font-weight: normal;
                }
              }
            }
          }
        }
      }

      &--is-error {
        @extend %--is-error;

        &.c-forms__select--has-focus {
          @extend %--is-error--has-focus;
        }

        &:after {
          background: $fadeGradientError;
        }
      }

      @content;
    }
  }
}
