@import '../Framework/platform-mixins/Chevron.scss';

@mixin ToggleContentContainer($chevronColor: black) {
  .togglecontent-container {
    &__summary {
      list-style: none;
      &::-webkit-details-marker {
        display: none;
      }
      & > .togglecontent-container__heading__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        @include Chevron(
          $className: chevron--toggle-content,
          $chevronColor: $chevronColor,
          $lineWidth: 3px,
          $lineLength: 12px,
          $borderRadius: 0 0 20px 20px,
          $height: 50px,
          $top: 21px
        );

        h2,
        h3,
        h4,
        h5,
        h6 {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: none;
          margin: 0;
          padding-top: 10px;
          padding-bottom: 10px;

          @include breakpoint(medium) {
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
    &[open] {
      & > .togglecontent-container__summary {
        & > .togglecontent-container__heading__container {
          .chevron--toggle-content {
            @include ChevronOpen();
          }
        }
      }
    }
    @content;
  }
}
