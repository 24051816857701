@mixin Meganav($className: 'c-meganav',
  $width: 1280px,
  $bgColor: #fff,
  $boxShadow: 0 3px 10px 3px rgba(#000, 0.2),
  $columns: 3,
  $columnGap: (18px,
    18px,
    18px,
  ),
  $itemContainerPadding: 0,
  $itemPadding: 2px 0 8px,
  $itemSelectedBorderColor: #000,
  $borderColor: #000) {
  .#{$className} {
    background-color: $bgColor;
    box-shadow: $boxShadow;
    display: none;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 20;
    min-height: 173px;

    .c-toppnav__item--is-opened & {
      display: block;
    }

    &__container {
      max-width: $width;
      margin: 0 auto;
      position: relative;

      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid $borderColor;

        a {
          text-decoration: none;
          font-size: 1.25rem;
          font-weight: bold;
          @include focus-handler();
        }

        &__close-btn {
          display: inline-flex;
          align-items: center;
          background-color: transparent;
          font-size: 1rem;
          cursor: pointer;
          @include icon('e84e');
          @include focus-handler();

          &:before {
            font-size: 30px;
          }
        }
      }
    }

    &__list {
      columns: $columns;
      column-gap: nth($columnGap, 1);

      @include breakpoint(xlarge) {
        column-gap: nth($columnGap, 2);
      }

      @include breakpoint(xxlarge) {
        column-gap: nth($columnGap, 3);
      }

      list-style: none;
      margin: 0;
      position: relative;
      z-index: 2;

      &__item {
        break-inside: avoid;
        margin-bottom: 0;
        padding: $itemContainerPadding;

        a {
          display: inline-block;
          padding: $itemPadding;
          width: 100%;
        }

        span {
          line-height: 1.38;
        }
      }

      &--is-selected {
        a {
          text-decoration: none;

          span {
            &:after {
              border-color: $itemSelectedBorderColor;
              width: 100%;
            }
          }
        }
      }
    }

    @content;
  }
}
