@mixin button(
  $bg: pink,
  $border: 1px solid yellow,
  $border-radius: 25px,
  $color: brown,
  $className: 'button',
  $hoverBg: grey,
  $hoverColor: orangered
) {
  .#{$className} {
    @include secondary-font-family();
    background: $bg;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    cursor: pointer;
    font-size: rem-calc(20);
    font-weight: 500;
    padding: 10px 25px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: $hoverBg;
      color: $hoverColor;
      outline: none;
    }

    @content;
  }
}
