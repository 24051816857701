@import '../../../platformui/SearchBlock/list.scss';
@import '../../../platformui/SearchBlock/listitem.scss';

@include search-list-item($containerPadding: 20px, $itemBgColor: $white) {
  &__item {
    text-decoration: none;
    @include focus-handler($color: c('grey-500'));

    .c-related__item__information-type {
      height: 16px;
      line-height: 1;
      margin-top: 12px;
      margin-bottom: 18px;
      padding-left: 6px;
      color: c('stone-dark');

      &--1001 {
        border-left: 8px solid c('main');
      }

      &--1002 {
        border-left: 10px solid c('sky-clear');
      }

      &--1003 {
        border-left: 10px solid c('grass-base');
      }

      &--1004 {
        border-left: 10px solid c('sun-clear');
      }
    }

    &__selection-code {
      line-height: 1;
      margin-top: 8px;
      margin-bottom: 18px;
      display: flex;
      gap: 1rem;
    }

    &[href$='.pdf'] {
      .c-related__item__heading {
        @include icon('e82b', $pseudo: after);
      }
    }

    &[href$='.doc'],
    &[href$='.docx'] {
      .c-related__item__heading {
        @include icon('e833', $pseudo: after);
      }
    }

    &[href$='.ppt'],
    &[href$='.pptx'] {
      .c-related__item__heading {
        @include icon('e831', $pseudo: after);
      }
    }

    &[href$='.xls'],
    &[href$='.xlsm'],
    &[href$='.xlsx'] {
      .c-related__item__heading {
        @include icon('e832', $pseudo: after);
      }
    }

    &[href$='.txt'],
    &[href$='.xml'],
    &[href$='.gif'],
    &[href$='.jpg'],
    &[href$='.jpe'],
    &[href$='.jpeg'],
    &[href$='.gif'],
    &[href$='.bmp'],
    &[href$='.png'],
    &[href$='.mp3'],
    &[href$='.zip'],
    &[href$='.webp'] {
      .c-related__item__heading {
        @include icon('E867', $pseudo: after);
      }
    }

    &__anchor {
      text-decoration: none;
    }

    &__heading {
      margin: 0;
      font-weight: bold;
      font-size: 1.25rem;
      color: c('sky-base');
      margin-bottom: 12px;
      word-break: break-all;

      &:hover {
        text-decoration: underline;
      }
    }

    &__text {
      color: c('stone-dark');
    }

    &__container {
      padding: 16px 0px 15px;
    }
  }
}
