@mixin Section(
  $alternatingBgColor: rgba(0, 0, 0, 0.08),
  $greyBgColor: rgba($black, 0.05),
  $headingMaxWidth: 460px,
  $sectionPadding: (
    30px 0 20px,
    30px 0 20px,
    60px 0 40px,
  ),
  $sectionIntroPadding: (
    25px 0 0,
    20px 0 27px,
  ),
  $listview: c-section__header--listview-shown,
  $heading: c-section__header--heading-shown,
  $doubleColumnBlock: c-section__header--double-column,
  $headingJustify: center,
  $marginJustify: 20px
) {
  .c-section {
    @media print {
      display: none;
    }

    &__inner {
      padding: nth($sectionPadding, 1);
      position: relative;
      
      @include breakpoint(large) {
        padding: nth($sectionPadding, 3);
      }

      ul {
        padding-left: 0;
      }
    }

    &__header {
      display: flex;

      &.#{$listview} {
        margin-bottom: 0;

        @include breakpoint(medium) {
          justify-content: flex-end;
          margin-bottom: 30px;
        }
      }

      &.#{$heading} {
        justify-content: $headingJustify;
        margin-bottom: $marginJustify;

        @include breakpoint(medium) {
          margin-bottom: 30px;
        }
      }

      &.#{$listview}.#{$heading} {
        justify-content: space-between;
      }
    }

    &--intro {
      .c-section__inner {
        padding: nth($sectionIntroPadding, 1);

        @include breakpoint(large) {
          padding: nth($sectionIntroPadding, 2);
        }
      }

      .page--collection-page & {
        .c-section__inner > .row > .columns {
          @include breakpoint(medium only) {
            /* This is quick fix for aligning text in the content with the intro. See https://inera.atlassian.net/browse/K2IVT-850 */
            padding-inline: 0;
          }
        }
      }
    }

    &--chapter-container {
      padding-inline: rem-calc(20);

      @include breakpoint(medium) {
        padding-inline: rem-calc(10);
      }
    }

    &--blue {
      background: $alternatingBgColor;

      .c-section__inner {
        padding: nth($sectionPadding, 1);

        @include breakpoint(large) {
          padding: nth($sectionPadding, 3);
        }
      }

      & + .c-section--blue {
        .c-section__inner {
          margin-top: -30px;
          padding-top: 0;
        }

        .c-section__heading {
          margin-top: 30px;
        }
      }
    }

    &--grey {
      background-color: $greyBgColor;

      .c-section__inner {
        padding: nth($sectionPadding, 1);

        @include breakpoint(large) {
          padding: nth($sectionPadding, 3);
        }
      }

      & + .c-section--grey {
        .c-section__inner {
          margin-top: -30px;
          padding-top: 0;
        }

        .c-section__heading {
          margin-top: 30px;
        }
      }
    }

    &--last {
      .c-section__inner {
        padding: 30px 0 60px;

        @include breakpoint(large) {
          padding: 60px 0 120px;
        }
      }
    }

    &--no-margin {
      .c-section__inner {
        padding: 30px 0 0;

        @include breakpoint(large) {
          padding: 60px 0 0;
        }
      }
    }

    &__heading {
      color: inherit;
      margin-bottom: 0;
      margin-top: 0;
      max-width: $headingMaxWidth;
    }

    .u-article {
      &--center {
        justify-content: center;
      }
    }

    @content;
  }
}
