@import '../../platformui/InfoBanner/styles.scss';

@include InfoBanner(
  $backgroundColor: c('stone'),
  $borderRadius: 5px,
  $btnFontColor: c('sky-base'),
  $modalLinkColor: c('stone-dark')
) {
  .info-banner {
    border-left-width: 8px;
    border-left-style: solid;
    position: sticky;
    z-index: 2;
    transition: all 0.3s linear;

    @include breakpoint(medium) {
      max-height: 50px;
    }

    &--offset {
      top: 60px;
    }

    &__type {
      &--kunskapsstod {
        border-left-color: c('main');
      }

      &--vardforlopp {
        border-left-color: c('sky-clear');
        .info-banner {
          &__content {
            padding: 0 0 0 10px;
          }
          &__first-item,
          &__second-item {
            align-self: center;
          }
          &__first-item {
            justify-self: flex-start;
          }
          &__second-item button {
            font-size: 1rem;
            margin: 0;
            &::after {
              margin: 0 0 4px 5px;
            }
          }
          &__subject {
            font-size: 1rem;
          }
        }
      }

      &--vardprogram {
        border-left-color: c('grass-base');
      }

      &--vardriktlinjer {
        border-left-color: c('sun-clear');
      }
    }

    &__content {
      border-left: 2px solid c('white');
    }

    &__subject {
      @include secondary-font-family();
      letter-spacing: -0.4px;
    }

    &__title {
      @include breakpoint(large) {
        border-left: 1px solid c('stone-base');
        padding-left: 10px;
      }
    }

    .c-listselect__list__item__link__tooltip {
      top: 60px;
    }
  }
}
