@import '../../platformui/Mobilenav/expand-button/styles.scss';

@include ExpandButton(
  $className: 'plus--subheading',
  $topPosition: unset,
  $bgColorBtn: c('sky-base'),
  $btnHeight: 30px,
  $btnWidth: 30px,
  $btnBorderRadius: 100%,
  $plusMinusColor: c('white')
) {
  right: 0px;
  @include breakpoint(large) {
    display: block;
  }

  &--is-open {
    &:before {
      transform: rotate(90deg);
    }
    &::after {
      transform: rotate(180deg);
    }
  }

  @media print {
    display: none;
  }
}
