@mixin spinner($className: 'c-spinner', $bgColor: red, $margin: 65px auto 0) {
  .#{$className} {
    display: block;
    margin: $margin;
    width: 70px;

    span {
      display: block;
      width: 18px;
      height: 18px;
      background-color: $bgColor;

      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      animation-delay: -0.32s;
    }

    .bounce2 {
      animation-delay: -0.16s;
    }

    @content;
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
}
