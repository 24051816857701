@mixin pagination($className: 'c-pagination', $fontSize: 20px) {
  .#{$className} {
    font-size: $fontSize;
    display: inline-block;
    overflow: hidden;
    position: relative;
    transition: all 0.2s ease;

    .c-spinner {
      opacity: 0;
    }

    .is-loading & {
      outline: none;
      .c-spinner {
        opacity: 1;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &__text {
      display: inline-block;
      opacity: 1;
      transition: all 0.05s ease;
      //loading state
      .is-loading & {
        opacity: 0;
      }
    }

    &__showall {
      position: relative;
      right: auto;
      border-color: transparent;
      font-size: inherit;
      text-transform: none;

      @include breakpoint(large) {
        position: absolute;
        right: 0;
      }
      &:before {
        content: ' ';
        font-size: $fontSize; /* vertical align text with main load more button */
      }
    }

    @content;
  }
}
