@mixin SelectedRegionArrow($color: transparent) {
  @include icon('E85A');
  &:before {
    font-size: 15px;
    font-weight: bold;
    color: $color;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 666;
    border: 1px solid c('sky-base');
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: c('almost-white');
  }

  &:after {
    content: '';
    position: absolute;
    color: white;
    left: 0px;
    top: 10px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid c('sky-base');
    background-color: c('sky-base');
  }
}

.regions {
  &__listitem {
    &__button:hover {
      span {
        @include SelectedRegionArrow($color: c('sky-base'));
      }
    }
    &__icon {
      @include SelectedRegionArrow();

      &:after {
        content: none;
      }

      &.selected-region {
        @include SelectedRegionArrow($color: c('sky-base'));
      }
    }
  }

  &__close-btn {
    background: transparent;
    @include breakpoint(medium) {
      @include breakpoint(large);
      @include icon('e84e');
    }
  }
}
