@import '../../platformui/CollapsiblePanel/styles.scss';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include CollapsiblePanel(
  $btnBoxShadow: 0 -4px 6px 0 rgba(c('black'), 0.3),
  $iconContainerBg: rgba(c('white'), 0.1),
  $iconContainerTop: 0,
  $iconContainerRight: 0,
  $iconContainerWidth: 40px,
  $iconPosition: 50%,
  $outline: none,
  $outlineOffset: -1px
) {
  &__button {
    background: c('stone');
    box-shadow: none;
    @include focus-handler($color: c('black'), $offset: -2px);

    &__inner {
      align-items: center;
      color: c('nav');
      display: flex;
      font-size: rem-calc(16);
      font-weight: bold;
      justify-content: space-between;
      line-height: 1.5;
      padding: 4px 15px;
      @include secondary-font-family();
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &__icon-container {
      background: none;
      height: 40px;
      position: relative;
      width: 40px;
    }

    @include Chevron(
      $className: c-collapsible-panel__button__icon,
      $chevronColor: c('denim'),
      $height: 40px,
      $top: 13px
    ) {
      background-color: transparent;
      bottom: 0;
      left: 0;
      position: absolute;

      top: 0;
    }
  }
}
