@import '../../platformui/Teaser/styles.scss';
@import './teaser-linklist.scss';

@include Teaser(
  $headingLinkColor: c('secondary-dark'),
  $headingMargin: 0 0 14px 0,
  $headingPadding: 0 0 14px 0,
  $componentBorderRadius: (
    25px,
    10px,
    10px,
  ),
  $componentPageListBorderBottomHover: none,
  $componentPageListBorderBottom: none,
  $componentBoxShadow: 0 0 2px 0 rgba(0, 0, 0, 0.3),
  $translationColor: c('grey-500')
) {
  height: auto;

  $eqHeight: '.c-teaser--eq-height';
  $hasImg: '.c-teaser--has-img';
  //.c-teaser
  text-decoration: none;

  .u-columns--is-listview &,
  .u-flexwrap--is-listview & {
    border-radius: 25px;
    height: auto;
  }

  &__img {
    margin: 18px 18px 0;
  }

  &__wrapper {
    position: relative;

    #{$hasImg} & {
      &:after {
        top: 336px;
      }
    }
  }

  &-outer {
    &:last-child:not(:first-child) {
      float: left;
    }
  }

  &--eq-height {
    @include focus-handler($color: c('grey-500'), $offset: 8px);
    height: auto;
  }

  &__img-container {
    display: none;
    border-top: 5px solid c('denim');

    #{$eqHeight} & {
      display: none;

      @include breakpoint(medium) {
        display: block;

        .c-image {
          margin: 18px 18px 0;
        }
      }

      > div {
        background-color: transparent;
      }
    }

    @include breakpoint(medium) {
      display: block;

      .u-columns--is-listview &,
      .u-flexwrap--is-listview & {
        display: none;
      }
    }
  }

  &__heading {
    @include secondary-font-family();
    font-size: rem-calc(20);
    line-height: 1.25;
    letter-spacing: -0.3px;
    margin: 0;
    padding: 0;

    @include breakpoint(medium) {
      border-bottom: 1px solid c('stone-line');
      margin: 0 0 14px 0;
      padding: 0 0 14px 0;

      .u-columns--is-listview &,
      .u-flexwrap--is-listview & {
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
    }

    &__link {
      @include icon('f105');
      text-decoration: none;

      &:before {
        color: c('denim');
        position: absolute;
        top: 1px;
      }

      span {
        padding-left: rem-calc(20);
        display: inline-block;
        color: c('denim');

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(20);
        }
      }

      @include breakpoint(medium) {
        .u-columns--is-listview &,
        .u-flexwrap--is-listview & {
          max-width: 100%;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__content {
    @include breakpoint(medium) {
      border-top: 5px solid c('denim');

      .u-columns--is-listview &,
      .u-flexwrap--is-listview & {
        border-top: none;
        height: 50px;

        html:not([lang='sv']) & {
          height: auto;
        }
      }
    }

    &--with-img {
      border: none;
    }

    #{$hasImg} & {
      @include breakpoint(medium) {
        border-top: 0;
      }
    }

    padding: 10px 20px;

    @include breakpoint(medium) {
      padding: 20px 20px 12px;

      .u-columns--is-listview &,
      .u-flexwrap--is-listview & {
        padding: 12px 20px;
      }
    }

    #{$eqHeight} & {
      padding: 10px 20px;

      @include breakpoint(medium) {
        padding: 20px 20px 0;
      }
    }
  }

  &__text {
    display: none;
    color: c('black');

    @include breakpoint(medium) {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      .u-columns--is-listview &,
      .u-flexwrap--is-listview & {
        display: none;
      }
    }
  }

  &__translation {
    font-family: $secondary-font;
  }
}
