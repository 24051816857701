@import '../Modal/fullscreen-modal.scss';
@mixin ZoomContainer(
  $cta: red,
  $muted: purple,
  $linkHover: blue,
  $white: green,
  $black: aqua
) {
  .zoom-container {
    position: relative;

    @include FullscreenModal(
      $className: 'zoom-fullscreen',
      $open: '.zoom-fullscreen__modal-open--open',
      $cta: $cta,
      $muted: $muted,
      $linkHover: $linkHover,
      $white: $white,
      $black: $black
    ) {
      &__modal-open {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        right: 0;
        padding: 0;
        top: 20px;
        height: 40px;

        span {
          margin-right: 50px;
          text-decoration: none;
        }

        &:hover,
        &:focus {
          &:after {
            transform: scale(1.2);
          }
        }

        @include icon('e80e', true, true, after);

        &:after {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          position: absolute;
          right: 0;
          font-size: 25px;
          color: $white;
          transition: all 0.4s cubic-bezier(0, 0, 0.04, 1.57);
          text-decoration: none;
        }

        &:before {
          content: '';
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          background-color: $cta;
          font-size: 25px;
          right: 0;
          margin-right: 0;
          position: absolute;
          width: 40px;
          height: 40px;
          text-decoration: none;
        }
      }

      &__modal-close {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: fit-content;
        box-shadow: none;
        border-radius: 0;
        left: calc(100% - 105px);
        top: 45px;

        @include breakpoint(large) {
          top: 90px;
        }

        &:has(span.zoom-fullscreen__modal-close__text) {
          @include breakpoint(medium) {
            left: calc(100% - 140px);
          }

          @include breakpoint(large) {
            left: calc(100% - 200px);
          }
        }

        @include icon('e84e', true, true, after);

        &__text {
          color: $cta;
          font-size: 1rem;
          margin-right: 45px;
        }

        &::before {
          content: none;
        }

        &::after {
          display: inline-block;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          color: $white;
          right: 0;
          top: 0;
          border-radius: 100px;
          background-color: $cta;
          height: 40px;
          width: 40px;
        }
      }
    }
    @content;
  }
}
