@mixin Breadcrumb(
  $className: 'c-breadcrumb',
  $textColor: #000,
  $outerMargin: 15px 0 10px,
  $itemPadding: 6px 4px 6px 0
) {
  .#{$className} {
    color: $textColor;
    display: none;
    list-style: none;
    margin: $outerMargin;
    padding: 0;

    @media print {
      margin: 0 !important;
      color: $black;
    }

    @include breakpoint(large) {
      display: inline-flex;
    }

    &__label {
      padding-top: 6px;
      padding-right: 8px;
    }

    ol {
      font-size: inherit;
      margin: 0;
      padding: 0;
    }

    &__item {
      display: inline-block;
      font-size: inherit;
      margin: 0;
      padding: $itemPadding;

      a {
        display: inline-block;
        min-height: 24px;
      }

      &:after {
        content: '/';
        display: inline-block;
        margin-left: 4px;

        [dir='rtl'] & {
          content: '\\';
          margin-left: 0;
          margin-right: 4px;
        }
      }

      &:last-child {
        &:after {
          content: '';
        }
      }

      /** 
        <a aria-current="page">text</a> 
        <a aria-current="location">text</a> 
      */
      [aria-current] {
        color: inherit;
        text-decoration: inherit;
      }

      @media print {
        a {
          color: $black;
          text-decoration: none;
        }

        [aria-current] {
          font-weight: bold;
        }
      }
    }

    @content;
  }
}
