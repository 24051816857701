@import '../../platformui/Meganav/styles.scss';

@include Meganav($width: $global-width,
  $bgColor: c('white'),
  $boxShadow: 0 4px 12px 0 c('stone-line'),
  $columnGap: (20px,
    30px,
    40px,
  ),
  $columns: 4,
  $itemContainerPadding: 3px 0,
  $itemPadding: 5px 10px 5px 25px,
  $borderColor: c('stone-line')) {
  padding: 30px 0 50px;

  &__list {
    &__item {
      border-bottom: solid 1px c('stone-line');

      a {
        color: c('nav');
        text-decoration: none;

        @include focus-handler($offset: 1px);

        @include link-arrow($top: 15px,
          $left: 3px,
          $fontSize: rem-calc(6),
          $bgSize: rem-calc(16),
          $bgTop: 10px);
      }
    }
  }

  &__container {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);

    &__header {
      a {
        @include secondary-font-family();
        color: c('nav');
        position: relative;

        &:after {
          display: inline-block;
          position: absolute;
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.45327 4.54673C5.70361 4.79706 5.70361 5.20294 5.45327 5.45327L1.0943 9.81225C0.843963 10.0626 0.438088 10.0626 0.187752 9.81225C-0.062584 9.56191 -0.062584 9.15604 0.187752 8.9057L4.09345 5L0.187752 1.0943C-0.0625836 0.843963 -0.0625836 0.438088 0.187752 0.187752C0.438089 -0.062584 0.843963 -0.062584 1.0943 0.187752L5.45327 4.54673Z' fill='%23A00B36'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          height: 10px;
          width: 10px;
          top: 10px;
          right: -20px;
        }
      }

      &__close-btn {
        color: c('nav');
      }
    }
  }
}
