@mixin ListSelect(
  $activeItemBorderBottom: none,
  $listBorderBottom: none,
  $activeItemBackground: transparent,
  $inactiveItemBackground: transparent,
  $tooltipBackgroundColor: white,
  $tooltipColor: black,
  $tooltipBoxShadow: none,
) {
  .c-listselect {
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;

    &__label {
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    &__list {
      display: flex;
      flex-direction: row;
      border-bottom: $listBorderBottom;
      padding: 0 5px;
      list-style-type: none;

      &__item {
        display: flex;
        flex-direction: column;
        margin: 0 2px;
        padding: 0 8px;

        &__link {
          text-decoration: none;

          &__tooltip {
            display: none;
            position: absolute;
            top: -45px;
            right: 0;
            font-style: normal;
            color: $tooltipColor;
            background-color: $tooltipBackgroundColor;
            box-shadow: $tooltipBoxShadow;
            border-radius: 5px;
            padding: 10px 20px;
          }

          &:hover {
            cursor: pointer;
            text-decoration: underline;
            
            + .c-listselect__list__item__link__tooltip {
              display: block;
            }
          }

          &--active {
            background-color: $activeItemBackground;
            border-bottom: $activeItemBorderBottom;
            margin-bottom: -4px;
          }

          &--inactive {
            background-color: $inactiveItemBackground;
            font-size: 1rem;
          }
        }
      }
    }
    @content;
  }
}
