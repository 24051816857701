@import './subject-intro-linklist.scss';
@import '../Linklist/styles.scss';
@mixin SubjectIntro(
  $backgroundColor: yellow,
  $preambleHoverColor: pink,
  $subjectColor: red,
  $subjectTextColor: red,
  $linkListColor: orange,
  $linkListHoverColor: lightblue,
  $imageMaxWith: 960px,
  $height: 495px,
  $backgroundImage: none
) {
  .subject-intro {
    position: relative;
    top: 1px;

    .row.expanded {
      margin: 0;
    }

    @include breakpoint(large) {
      height: $height;

      &:after {
        background-color: $white;
        bottom: -1px;
        content: '';
        height: 3px;
        position: absolute;
        width: 100%;
      }
    }

    &__text {
      align-items: center;
      display: flex;
      background-image: $backgroundImage;
      background-repeat: no-repeat;
      background-size: cover;
      padding-bottom: 30px;
      position: relative;
      top: -20px;

      &:after {
        background-color: $white;
        bottom: -35px;
        content: '';
        height: 30px;
        position: absolute;
        right: 0;
        width: 100%;
      }

      @include breakpoint(medium) {
        top: -30px;
      }

      @include breakpoint(large) {
        background-color: $backgroundColor;
        height: $height;
        padding-bottom: 0;
        top: 0;

        &:after {
          background-color: transparent;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3MTAiIGhlaWdodD0iNDk1IiB2aWV3Qm94PSIwIDAgNzEwIDQ5NSI+CiAgICA8cGF0aCBmaWxsPSIjRjFGMUY1IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uNTc1IDBoNzA5LjQ1djQ5NUgwYzIyLjgyOS03OS4xMSAzNS4wMjUtMTYyLjQ0MiAzNS4wMjUtMjQ4LjVDMzUuMDI1IDE2MS4xNjcgMjMuMDMzIDc4LjUxNC41NzUgMHoiLz4KPC9zdmc+Cg==');
          background-repeat: no-repeat;
          bottom: 0;
          content: '';
          height: $height;
          right: 35px;
        }
      }

      &__content {
        margin: 0 auto;
        padding: 20px;
        z-index: 1;

        @include breakpoint(medium) {
        }

        @include breakpoint(large) {
          margin: 0 0 0 100px;
          max-width: 500px;
          padding: 0;
          position: relative;
        }
      }
    }

    &__preamble {
      p {
        font-size: rem-calc(18);
        line-height: 28px;
      }
      a {
        font-size: rem-calc(18);
        line-height: 28px;

        &:hover {
          color: $preambleHoverColor;
        }
      }
    }

    &__subject-heading {
      color: $subjectColor;
      font-size: rem-calc(24);
      margin: 25px 0 10px;

      @include breakpoint(medium) {
        margin: 70px 0 10px;
      }

      @include breakpoint(large) {
        font-size: rem-calc(36);
        margin: 0 0 10px;
      }

      span {
        color: $subjectTextColor;
      }
    }

    &__image-container {
      height: 290px;

      @include breakpoint(medium) {
        height: $height;
      }

      @include breakpoint(large) {
        float: right;
        height: $height;
        max-width: $imageMaxWith;
        position: relative;

        &:after {
          background: transparent;
          content: '';
          height: $height;
          left: 0;
          position: absolute;
          top: 0;
          width: 0;
        }
      }

      @include breakpoint(1900px) {
        &:after {
          background: linear-gradient(
            90deg,
            rgba(#f1f1f5, 1) 0%,
            rgba(#f1f1f5, 0.9) 35%,
            rgba(#f1f1f5, 0.8) 45%,
            rgba(#f1f1f5, 0.7) 55%,
            rgba(#f1f1f5, 0) 100%
          );
          width: 200px;
        }
      }

      > div {
        height: 100%;

        .c-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-height: $height;

          img {
            opacity: 0;
            height: 100%;
          }
        }
      }

      &__wrapper {
        @include breakpoint(large) {
          background-color: #f1f1f5;
          height: $height;
          position: relative;
          width: 100%;
        }
      }
    }
  }
  @content;

  @include Linklist($className: 'subject-intro-linklist') {
    @include SubjectIntroLinklist(
      $linkColor: $linkListColor,
      $hoverColor: $linkListHoverColor
    );
  }
}
