.subject-group {
  &__navigation {
    border-top: 1px solid c('sky-line');
    border-bottom: 1px solid c('sky-line');
  }

  &__block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include breakpoint(medium) {
      justify-content: space-between;
    }

    &__heading {
      width: 100%;
      @include secondary-font-family();
      font-size: 1.25rem;
      font-weight: normal;
      letter-spacing: 1.5px;
      line-height: 24px;
      text-transform: uppercase;

      @include breakpoint(medium) {
        width: auto;
      }
    }
  }

  &__list {
    border-top: 1px solid c('black');
    list-style-type: none;
    padding: 15px 0 0 0;
    margin-top: 0;
    column-gap: 40px;

    @include breakpoint(large) {
      &--one-col {
        column-count: 1;
      }
      &--two-col {
        column-count: 2;
      }
      &--three-col {
        column-count: 3;
      }
    }

    &__item {
      padding: 8px 0;

      a {
        text-decoration: none;
        font-size: 1rem;
        word-break: break-word;
        @include focus-handler();

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
        &[href$='.pdf'] {
          @include icon($content: 'E867', $pseudo: after);
        }
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 40px;
    }

    &__header {
      padding-left: 10px;
    }
  }

  &__type {
    &.medium-4 {
      width: calc(100% / 3 - 80px / 3);
    }
    &.medium-8 {
      width: calc(100% * 2 / 3 - 40px / 3);
    }

    &--kunskapsstod {
      &.col-12 {
        width: 100%;

        ul {
          column-count: 1;

          @include breakpoint(large) {
            column-gap: 40px;
          }
        }
      }

      .subject-group__list__header {
        border-left: 10px solid c('main');
      }
    }

    &--vardforlapp {
      .subject-group__list__header {
        border-left: 10px solid c('sky-clear');
      }
    }

    &--vardprogram {
      .subject-group__list__header {
        border-left: 10px solid c('grass-base');
      }
    }

    &--vardriktlinje {
      .subject-group__list__header {
        border-left: 10px solid c('sun-clear');
      }
    }

    &--sort-active {
      width: 100%;
      @include breakpoint(large) {
        max-width: calc(100% / 3 - 80px / 3);

        ul {
          column-count: 1;
        }
      }
    }
  }

  &__sort {
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border: 1px solid c('sky-line');
      border-radius: 100px;
      margin: 40px 0 30px 0;
    }
  }

  &__groupname {
    &__header {
      margin: 30px 0;

      a {
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }
}
