.process-activity {
  background-color: c('sky-background');
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;

  & > &__heading {
    margin-top: 0;
    color: c('black');
    margin-bottom: 10px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .pwt-text__heading {
      color: c('black');
      margin-bottom: 10px;
    }

    & > :not(:last-child) {
      border-bottom: 1px solid c('black');
    }
  }
}