@mixin MobileNavWrapper($bgColor: pink) {
  .c-mobilenav-wrapper {
    box-shadow: 0 2px 4px 0 rgba($black, 0.2);
    display: none;
    opacity: 0;
    position: relative;
    top: 0;
    transition: transform 0.3s ease, opacity 0.3s ease, bottom 0.3s ease;
    visibility: hidden;
    z-index: 0;
    background-color: $bgColor;

    .c-header--is-navigation & {
      display: block;

      @include breakpoint(large) {
        display: none;
      }
    }

    .c-header--is-animating & {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    @include breakpoint(large) {
      display: none;
    }
  }
  @content;
}

@mixin MobileNav(
  $className: c-mobilenav,
  // also usable for c-metanav
  $listBgColor: transparent,
  $topListItemOpen: transparent,
  $listItemColor: $white,
  $expandButtonBg: transparent,
  $isSelectedBg: transparent,
  $isSelectedBorderLeft: transparent,
  $fontSize: rem-calc(18),
  $displayState: block,
  $background: transparent
) {
  .#{$className} {
    background: $background;
    @if $className == c-metanav {
      background-color: white;
    }

    &__footer-links {
      padding: 20px 20px;
      margin: 0;
      @include focus-handler();
      li {
        padding-bottom: 14px;
        list-style: none;
        a {
          color: $listItemColor;
          font-size: 0.875rem;
          text-decoration: none;
        }
      }
    }

    &__list {
      background-color: $listBgColor;
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;

      [dir='rtl'] & {
        margin: 0;
        padding: 0;
      }

      &:before {
        content: '';
        position: absolute;
        background-color: $expandButtonBg;
        height: 100%;
        width: 40px;
        right: 0;
      }

      &__item {
        box-shadow: 0 -2px 4px 0 rgba($black, 0.2);
        display: block;
        margin-bottom: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 3;

        &__container {
          position: relative;

          a,
          span {
            // @include subheading-medium-font-family();
            // @include topnavigation-text();
            display: block;
            text-decoration: none;
            padding: 14px 20px;
            font-size: $fontSize;
            color: $listItemColor;
            border-left-color: transparent;
            border-right-color: transparent;

            &:hover {
              text-decoration: none;
              color: $listItemColor;
            }

            &:focus {
              outline: none;
            }
          }

          .#{$className}--selected-item > & {
            background-color: $isSelectedBg;

            a {
              border-left-color: $isSelectedBorderLeft !important;

              [dir='rtl'] & {
                border-left-color: transparent !important;
                border-right-color: $isSelectedBorderLeft !important;
              }
            }
          }
        }

        //States
        & > .#{$className}__list {
          display: none;
        }

        &--is-open {
          & > .#{$className}__list {
            display: $displayState;
          }
        }

        &--has-children {
          .#{$className}__list__item__container {
            a {
              padding-right: 40px;

              [dir='rtl'] & {
                padding-left: 40px;
                padding-right: 20px;
              }
            }
          }
        }
      }
      @content;
    }
  }
}

// --- Mixin for indents in mobile navigation,
// --- must be used before MobileNavSubLevel

@mixin SubLevelIndent($textIndent: 0, $className: c-mobilenav) {
  // also usable for c-metanav
  .#{$className}__list {
    .#{$className}__list__item {
      .#{$className}__list__item__container {
        a {
          padding-left: $textIndent;

          [dir='rtl'] & {
            padding-left: 0;
            padding-right: $textIndent;
          }
        }
      }
    }
    @content;
  }
}

@mixin MobileNavSubLevel(
  $className: c-mobilenav,
  // also usable for c-metanav
  $bgColor: $white,
  $borderTopColor: #000,
  $borderOnHoverColor: transparent,
  $listItemBorderLeftColor: transparent,
  $listItemBorderLeftWidth: 10px,
  $isOpenBg: transparent,
  $isOpenBorderLeft: transparent,
  $hasBorder: true,
  $textColor: #484848,
  $fontSize: 17px,
  $sublevelBoxShadow: none,
  $borderStyle: solid,
  $containerBgColor: transparent
) {
  .#{$className}__list__item {
    .#{$className}__list {
      background-color: $bgColor;

      .#{$className}__list__item {
        @if ($hasBorder == true) {
          box-shadow: none;
          border-top-style: solid;
          border-top-width: 1px;
          border-top-color: $borderTopColor;
        }

        &__container {
          a {
            border-left-style: $borderStyle;
            border-left-width: $listItemBorderLeftWidth;
            padding-left: calc(
              20px - #{$listItemBorderLeftWidth}
            ); // indent for sublevel 1
            color: $textColor;
            font-size: $fontSize;
            font-family: inherit;
            background-color: $containerBgColor;

            [dir='rtl'] & {
              border-left-style: none;
              border-left-width: 0;
              border-right-style: solid;
              border-right-width: $listItemBorderLeftWidth;
              padding-right: calc(
                20px - #{$listItemBorderLeftWidth}
              ); // indent for sublevel 1;
            }

            &:hover {
              border-left-color: $borderOnHoverColor;

              [dir='rtl'] & {
                border-left-color: transparent;
                border-right-color: $borderOnHoverColor;
              }
            }
          }
        }

        &--is-open {
          ul a {
            font-size: $fontSize;
            border-left-color: $listItemBorderLeftColor;

            [dir='rtl'] & {
              border-left-color: transparent;
              border-right-color: $listItemBorderLeftColor;
            }
          }

          //  style for --selected-item and --is-open
          & > div > {
            a {
              background-color: $isOpenBg;
              border-left-color: $isOpenBorderLeft;
              box-shadow: $sublevelBoxShadow;
              font-size: $fontSize;

              [dir='rtl'] & {
                border-left-color: transparent;
                border-right-color: $isOpenBorderLeft;
              }
            }
          }
        }
      }
      @content; // input ExpandButton and SubLevelIndent
    }
  }
}

@import 'expand-button/styles';
