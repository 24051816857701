@import '../../platformui/ImageGallery/styles';

@include ImageGallery(
  $background: transparent,
  $icon: 'f105',
  $iconSizeSmall: 25px,
  $iconPositionSmall: -28px,
  $iconSizeMedium: 40px,
  $iconPositionMedium: -12px,
  $currentBgOpacity: 0.1,
  $currentFontWeight: normal
) {
  padding: 0 10px;
  clear: both;

  @media print {
    margin: 0 !important;
    padding: 30px 50px 10px 0;

    [dir='rtl'] & {
      padding: 30px 0 10px 50px;
    }
  }

  @include breakpoint(large) {
    overflow: visible;

    &__content,
    &__items-container {
      overflow: visible;
    }
  }

  &__wrapper {
    @media print {
      border: none !important;
    }
  }

  &__ctrls {
    &--no-images {
      padding: 0;
      top: 45%;
    }
  }

  &__current {
    color: c('grey-500');
    font-size: 14px;
  }

  &__current-container {
    bottom: 30px;
    padding: 0 20px;
    position: absolute;

    @include breakpoint(medium) {
      margin-top: 0;
      padding: 0 60px;
    }
  }

  &__heading {
    padding: 0 10px;

    @include breakpoint(medium) {
      padding: unset;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0 0 73px;

    @media print {
      border-radius: 10px;
    }

    .show-for-print {
      margin: 10px 0 10px 15px;
      background-color: #e9e9e9;
      border-radius: 15px;
      padding: 3px 5px;
      width: 50px;
      font-size: 10px !important;

      span {
        font-size: 10px !important;
      }
    }

    &--no-images {
      @include breakpoint(medium) {
        padding: 20px 30px 30px 30px;
      }

      @include breakpoint(large) {
        padding: 30px 0;
      }
    }

    &__illustrator {
      align-self: flex-start;
      bottom: 0;
      color: c('grey-500');
      font-size: rem-calc(14);
      margin: auto 0 0;
      max-width: 100%;
      padding: 0 20px;
      position: relative;
      text-transform: uppercase;

      @include breakpoint(medium) {
        align-self: flex-end;
        margin-bottom: -38px;
        padding: 0 60px 0 170px;
      }
    }

    &__heading {
      padding: 0 20px;

      @include breakpoint(medium) {
        padding: 0 60px;
      }
    }

    &__text-container {
      padding: 0 20px;

      @include breakpoint(medium) {
        padding: 0 53px;
      }
    }
  }

  &__nextprev {
    background: transparent;

    @include focus-handler();

    @include breakpoint(large) {
      height: 50px;
      top: 50%;
      width: 50px;

      &:after {
        content: none;
      }

      &:before {
        color: c('denim');
        font-size: rem-calc(55);
        font-weight: bold;
        transition: color 0.2s ease;
      }

      &:hover {
        &:before {
          color: c('dusk');
        }
      }
    }

    @media print {
      display: none;
    }

    &:hover {
      &:after {
        background: c('denim');
      }
    }

    @include breakpoint(large) {
      &--next {
        right: -65px;
      }

      &--prev {
        left: -65px;
      }
    }
  }

  &__wrapper {
    border: 1px solid c('denim');
    border-radius: 10px;
    padding: 10px;

    @include breakpoint(medium) {
      padding: 0;
    }
  }
}
