h1 {
  @include secondary-font-family();
  font-size: rem-calc(32);
  font-weight: bold;
  letter-spacing: -0.83px;
  line-height: 1.08;
  color: c('stone-dark');
  margin: rem-calc(20) 0;
  @include breakpoint(large) {
    margin: rem-calc(20) 0 rem-calc(30);
    font-size: rem-calc(48);
    letter-spacing: -1.25px;
  }

  @media print {
    font-size: rem-calc(18) !important;
  }
}

h2 {
  @include secondary-font-family();
  color: c('stone-dark');
  font-size: rem-calc(24);
  font-weight: bold;
  letter-spacing: -0.4px;
  line-height: 1.25;
  margin: 20px 0 rem-calc(8);

  @include breakpoint(large) {
    font-size: rem-calc(28);
    margin: 20px 0 rem-calc(10);
    letter-spacing: -0.75px;
  }

  @media print {
    font-size: rem-calc(16) !important;
  }
}

h3 {
  @include secondary-font-family();
  color: c('stone-dark');
  font-size: rem-calc(22);
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 1.2;
  margin: 10px 0 rem-calc(6);

  @include breakpoint(large) {
    font-size: rem-calc(24);
    letter-spacing: -0.5px;
  }

  @media print {
    font-size: 13px !important;
  }
}

h4 {
  @include secondary-font-family();
  color: c('stone-dark');
  font-size: rem-calc(20);
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 1.25;
  margin: 0 0 rem-calc(6);

  @include breakpoint(large) {
    letter-spacing: -0.4px;
  }

  @media print {
    font-size: 9px !important;
  }
}

h5 {
  @include secondary-font-family();
  color: c('stone-dark');
  font-size: rem-calc(16);
  font-weight: bold;
  line-height: 1.25;
  margin: 0 0 rem-calc(6);

  @include breakpoint(large) {
    font-size: rem-calc(16);
  }

  @media print {
    font-size: 4px !important;
  }
}

.preamble {
  @include base-font-family();
  color: c('black');
  font-size: rem-calc(18);
  font-weight: 300;
  line-height: 1.64;
  margin: 0 0 30px;

  @include breakpoint(large) {
    font-size: rem-calc(22);
    line-height: 36px;
    margin: 0 0 40px;

    [dir='rtl'] & {
      font-size: 28px;
    }
  }

  [lang='rmn'] &,
  [lang='pl-PL'] &,
  [lang='rmf'] &,
  [lang='se-SE'] &,
  [lang='rmy-KA'] &,
  [lang='rmy-LO'] &,
  [lang='sr'] &,
  [lang='bs'] &,
  [lang='hr'] &,
  [lang='tr-TR'] &,
  [lang='kmr'] & {
    font-weight: 500;
  }

  p {
    color: c('black');
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 30px 0 0;

    &:first-child {
      margin: 0;
    }

    @include breakpoint(large) {
      margin: 40px 0 0;
    }
  }

  @media print {
    font-size: 10px;
    line-height: 1.5;
  }
}

p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  margin: 0 0 20px;
  word-wrap: break-word;

  [dir='rtl'] & {
    font-size: 18px;
  }

  @media print {
    font-size: 8px;
  }
}

a {
  color: c('sky-base');

  @media print {
    font-size: 8px;
  }
}

ul,
ol {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    list-style-type: none;
    overflow: hidden;
    list-style-position: outside;
    margin: 0 0 rem-calc(10);

    [dir='rtl'] & {
      font-size: rem-calc(18);
    }

    li {
      padding-bottom: rem-calc(10);
    }
  }
}

ul {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    padding: 0 0 0 rem-calc(32);

    [dir='rtl'] & {
      padding: 0 rem-calc(32) 0 0;
    }

    li {
      padding-left: rem-calc(6);
      position: relative;
      text-indent: rem-calc(1);

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: rem-calc(6);
      }

      @include breakpoint(medium) {
        padding-left: rem-calc(15);

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(15);
        }
      }
      &:before {
        background: c('black');
        border-radius: 50%;
        content: '\200B';
        display: block;
        height: rem-calc(8);
        left: 0;
        margin-left: rem-calc(-25);
        margin-right: rem-calc(15);
        position: relative;
        top: rem-calc(16);
        width: rem-calc(8);

        [dir='rtl'] & {
          margin-left: rem-calc(15);
          margin-right: rem-calc(-25);
          left: auto;
          right: 0;
        }

        @include breakpoint(medium) {
          left: rem-calc(-2);

          [dir='rtl'] & {
            left: auto;
            right: rem-calc(-2);
          }
        }

        @media print {
          content: none;
        }
      }
    }
  }
  @media print {
    list-style-type: disc !important;
    font-size: 8px;
  }
}

ol {
  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text & {
    counter-reset: item;
    padding: 0 0 0 rem-calc(34);

    [dir='rtl'] & {
      padding: 0 rem-calc(34) 0 0;
    }

    li {
      counter-increment: item;
      padding-left: rem-calc(6);
      text-indent: rem-calc(-2);

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: rem-calc(6);
      }

      @include breakpoint(medium) {
        padding-left: rem-calc(15);

        [dir='rtl'] & {
          padding-left: 0;
          padding-right: rem-calc(15);
        }
      }
      &:before {
        display: inline-block;
        font-weight: bold;
        margin-left: rem-calc(-25);
        margin-right: rem-calc(15);
        position: relative;
        content: counter(item) '.';

        [dir='rtl'] & {
          margin-left: rem-calc(15);
          margin-right: rem-calc(-25);
        }
      }

      @media print {
        text-indent: rem-calc(6);
      }
    }

    @media print {
      list-style-type: decimal;
      padding-left: rem-calc(28);
    }
  }

  @media print {
    font-size: 8px;
  }
}
