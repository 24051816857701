$darker-grey: #333;

@mixin ImageGallery(
  $ratio: 66.6666666666667%,
  $background: pink,
  $icon: 'none',
  $iconSizeSmall: 12px,
  $iconSizeMedium: 16px,
  $iconPositionSmall: -18px,
  $iconPositionMedium: 5px,
  $currentBgOpacity: 0.4,
  $currentFontWeight: 600,
  $focusColor: black
) {
  .c-image-gallery {
    margin: 0 -20px 20px -20px;
    overflow: hidden;
    padding: 20px 20px 0;

    &:before {
      content: '';
      display: block;
      margin: -20px -10px 20px -10px;
    }

    &:after {
      content: '';
      display: block;
      margin-left: -10px;
      margin-right: -10px;
    }

    @include breakpoint(medium) {
      margin: 0 -40px 30px -40px;
      padding: 30px 40px 10px;

      &:before {
        content: none;
        display: none;
      }
      &:after {
        content: none;
        display: none;
      }
      background: $background;

      @media print {
        margin: 0 !important;
        clear: both !important;
        page-break-inside: avoid;
        background: transparent;
      }
    }

    &__heading {
      margin-bottom: 20px;

      @media print {
        margin-bottom: 0;
      }
    }

    &__ctrls {
      left: 0;
      padding-top: $ratio;
      position: absolute;
      pointer-events: none;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &__nextprev {
      cursor: pointer;
      height: 100%;
      position: absolute;
      pointer-events: auto;
      top: 0;
      width: 25%;

      &:focus {
        outline: 2px solid $focusColor;

        outline-offset: -2px;

        @include breakpoint(large) {
          outline-offset: 4px;
        }
      }

      @include icon($icon);

      &:before {
        color: $white;
        font-size: $iconSizeSmall;
        position: absolute;
        top: 50%;
        height: 50px;
        line-height: 50px;
        margin-top: -25px;
        right: $iconPositionSmall;
        z-index: 2;

        @include breakpoint(small only) {
          right: 2px;
        }
        @include breakpoint(medium) {
          font-size: $iconSizeMedium;
          right: $iconPositionMedium;
        }
      }

      &:after {
        background: rgba($black, 0.4);
        border-radius: 100px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
        content: '';
        height: 50px;
        margin-top: -25px;
        position: absolute;
        right: -45px;
        width: 50px;
        top: 50%;
        z-index: 1;

        @include breakpoint(small only) {
          right: -25px;
        }

        @include breakpoint(medium) {
          box-shadow: none;
          height: 80px;
          margin-top: -40px;
          right: -40px;
          width: 80px;
        }
      }

      &--prev {
        transform: rotate(180deg);

        left: 0;
        @include breakpoint(small only) {
          left: -20px;
          [dir='rtl'] & {
            right: -20px;
          }
        }

        [dir='rtl'] & {
          left: auto;
          right: 0;
          transform: none;
        }
      }

      &--next {
        right: 0;
        @include breakpoint(small only) {
          right: -20px;
          [dir='rtl'] & {
            left: -20px;
          }
        }

        [dir='rtl'] & {
          left: 0;
          right: auto;
          transform: rotate(180deg);
        }
      }
    }

    &__current {
      background: rgba($black, $currentBgOpacity);
      border-radius: 100px;
      font-weight: $currentFontWeight;
      padding: 5px 10px;
      position: relative;
      display: inline-block;
      margin: 0;

      @media print {
        //display: none;
      }

      @include breakpoint(medium) {
        [dir='rtl'] & {
          right: 0px;
        }
      }

      [dir='rtl'] & {
        direction: rtl;
        right: 0px;
        text-align: right;

        span {
          direction: rtl;
          display: inline-block;
        }
      }
    }

    &__items-container {
      overflow: hidden;
      width: 100%;
    }

    &__items {
      display: flex;
      position: relative;
      list-style: none;
      margin: 0;
      padding: 0;
      counter-reset: img-counter; // counter for print

      @media print {
        display: block;
        transform: none !important;
      }
    }

    &__content {
      position: relative;

      @include breakpoint(medium) {
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
      }
    }

    &__item {
      flex-shrink: 0;
      transition: opacity 0.3s ease;
      opacity: 0;
      width: 100%;
      counter-increment: img-counter; // counter for print
      visibility: hidden;

      @media print {
        opacity: 1;
        flex-shrink: 1;
        border: 1px solid $black;
        page-break-inside: avoid;
        margin-top: 10px;
        padding: 20px;
        visibility: visible;
      }

      .show-for-print {
        display: none;
        @media print {
          display: block;
          float: right;
          font-size: 10px;
          font-weight: 600;

          span {
            position: relative;
            &:before {
              content: counter(img-counter); // counter for print
            }
          }
        }
      }

      &--current {
        opacity: 1;
        visibility: visible;
        position: relative;
      }

      &__heading {
        font-weight: bold;
      }

      &__linklist {
        margin-bottom: 25px;
      }

      &__illustrator {
        /*@include breakpoint(medium) {
          position: absolute;
          bottom: -5px;
          right: 0;
        } */

        @media print {
          font-size: 10px !important;
        }
      }

      &__text-wrapper {
        padding-bottom: 10px;
        @include breakpoint(large) {
          margin: 10px 7px 20px 7px;
          padding: 0;
        }
      }
    }

    .c-image {
      @media print {
        display: flex;
        justify-content: center;
      }
    }

    @content;
  }
}
