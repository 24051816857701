.show-for-large {
  @include breakpoint(large) {
    display: flex;
  }

  .columns {
    display: flex;
    flex-grow: 1;
  }
}

.c-linklist-footer {
  display: flex;
  width: 100%;
  flex-direction: column;

  ul {
    @include breakpoint(large) {
      flex-grow: 1;
    }
  }

  .c-linklist {
    width: 100% !important;

    &__header {
      font-weight: 700;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      letter-spacing: -0.5px;
      color: c('dusk');
    }

    &__list {
      padding-left: 5px;
    }
  }

  &.c-linklist--site-footer {
    flex-direction: column;
    column-gap: 50px;
    align-items: center;

    .c-linklist {
      width: 100%;

      @include breakpoint(large) {
        justify-content: flex-end;
      }

      ul {
        display: flex;
        flex-direction: column;
        border-left: none;
        width: 100%;

        @include breakpoint(large) {
          column-gap: 50px;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      li {
        margin: 8px 0;
      }
    }

    @include breakpoint(medium) {
      li,
      a {
        display: inline-block;
      }
    }

    .c-linklist__link {
      font-size: 0.875rem;
      color: c('white');
      text-align: left;

      @include breakpoint(large) {
        color: c('white');
      }

      padding-left: 0;

      @include breakpoint(large) {
        margin-right: 0;
        @include focus-handler($color: c('white'));
      }

      span {
        color: c('white');
      }
    }
  }

  &.footer-linklist {
    flex-grow: 1;

    li {
      padding: 0 0 10px 0;

      a {
        color: c('sky-base');
        @include focus-handler($color: c('sky-base'));
        cursor: pointer;
        
        span {
          text-decoration: none !important;
        }
      }
    }

    @include LinklistLink() {
      &:before {
        content: url('../../framework/images/arrow-right-blue.svg');
        width: 14px;
        top: 1px;
      }

      span {
        color: c('sky-base');

        &:hover {
          color: c('sky-dark') !important;
          text-decoration: underline !important;
        }
      }
    }
  }

  // Will not show arrow icon in mobile view
  &.c-linklist--no-icons {
    a {
      &:before {
        display: none;
        content: none;
      }
    }
  }
}
