@import '../linklist/styles.scss';

// modifier class, .teaser-linklist, in platformui
.c-linklist.teaser-linklist {
  &__list {
    li {
      &[dir='rtl'] {
        text-align: right;
      }
    }
  }

  &__header {
    font-weight: normal;
    text-transform: uppercase;
    color: c('grey-500');
    line-height: 1.2;
    font-size: 20px;
    position: relative;
    margin-bottom: 30px;

    @media print {
      margin-bottom: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: c('warm-grey');
      height: 1px;
      width: 43px;
      left: 0;
      bottom: -12px;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }

  @include LinklistLink($icon: '\F105') {
    padding-left: 18px;
    color: c('denim');

    &[href$="/"],
    &[href*="/#"],
    &[href*="http://"],
    &[href*="https://"]
    {
      &:before {
        font-size: 16px;
        top: 3px;
      }
    }

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 18px;
    }

    span {
      border-bottom: none;
      background-image: none;
      color: c('denim');

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
