.process-group {
  &__content {
    padding: 0px 8px 0px 8px;
  }

  &__container {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid c('sky-dark');
    & > .process-group__heading {
      @include secondary-font-family();
      font-size: 0.875rem;
      border-bottom: 0px;
      margin-top: 0;
      color: c('stone-dark');
      position: relative;
      top: -9px;
      width: fit-content;
      padding: 0 5px 0 5px;
      margin: 0 20px 0 20px;
      background-color: c('sky-background');
    }
  }
}
