.toggle-chapter {
  border-bottom: 1px solid rgba(c('grey-200'), 0.7);

  @include breakpoint(large) {
    border: none;
  }
  &__button {
    align-items: center;
    background: none;
    display: flex;
    padding: 20px 0;
    position: relative;
    text-align: left;
    width: 100%;

    [dir='rtl'] & {
      text-align: right;
    }

    @include focus-handler($color: c('grey-500'), $offset: 2px);

    @include breakpoint(large) {
      display: none;
    }
  }

  &__heading {
    color: c('denim');
    display: block;
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 28px;
    margin: 0;
    padding-right: 45px;
    text-align: left;

    [dir='rtl'] & {
      padding-left: 45px;
      padding-right: unset;
      text-align: right;
    }
  }

  &__wrapper {
    height: 0;
    overflow: hidden;

    @include breakpoint(large) {
      height: auto;
      overflow: visible;
    }

    &--is-open {
      height: auto;
      overflow: unset;
      outline: transparent;
    }

    @include breakpoint(large) {
      display: block;
    }
  }
}
