@mixin ImageBlock(
  $descriptionColor: #000,
  $photoCredColor: #636466,
  $marginBottom: (
    30px,
    30px,
    46px,
  ),
  $ratio: 66.6666666666667%,
  $printWidth: 100% !important,
  $textBg: salmon,
  $textHeight: 1px,
  $hoverBg: purple,
  $zoomBg: red,
  $zoomRight: 10px,
  $zoomCloseBg: orange,
  $zoomCloseColor: green,
  $zoomCloseFontSize: 27px,
  $printMargin: initial
) {
  .c-imageblock {
    clear: both;
    margin: 0 0 nth($marginBottom, 1) 0;

    @include breakpoint(medium) {
      margin: 0 0 nth($marginBottom, 2) 0;
    }

    @include breakpoint(large) {
      margin: 0 0 nth($marginBottom, 3) 0;
    }

    @media print {
      padding: 0;
    }

    &--main-image {
      @include breakpoint(medium) {
        margin: 0 -17.1774% nth($marginBottom, 2) -17.1774%;
      }

      @include breakpoint(large) {
        margin: 0 0 nth($marginBottom, 3) -34.3548%;

        [dir='rtl'] & {
          margin: 0 -34.3548% nth($marginBottom, 3) 0;
        }
      }

      @media print {
        margin: 0 !important;
      }
    }

    &--is-gallery {
      margin-bottom: rem-calc(15);
      .c-imageblock {
        &__image {
          background: #fff;
          overflow: hidden;
          padding-top: $ratio;
          position: relative;

          @media print {
            padding-top: 0;
          }

          &.c-image--is-lazyloaded {
            img {
              width: auto;
            }
          }

          img {
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            top: 50%;
            width: 100%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            @media print {
              position: relative;
              transform: none;
              left: 0;
              top: 0;
              margin: 0 auto !important;
            }
          }
        }

        &__text {
          margin-top: 4px;
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }

    &--no-margin {
      margin: 0;
    }

    &--medium-margin {
      margin-bottom: 30px;
    }

    &__text {
      color: $descriptionColor;
      font-weight: normal;
      margin-top: rem-calc(11);
      position: relative;
      width: 70%;

      &__photo-cred_text {
        margin-top: 10px;
        color: $photoCredColor;
        font-style: italic;
      }
      @media print {
        width: 100% !important;
      }

      @include breakpoint(medium) {
        width: 80%;
      }

      @include breakpoint(large) {
        width: 65%;
      }

      .c-imageblock--small-bleed & {
        width: 100%;

        @include breakpoint(medium) {
          padding-right: rem-calc(20);

          [dir='rtl'] & {
            padding-left: rem-calc(20);
            padding-right: 0;
          }
        }
      }

      .c-imageblock--small &,
      .c-imageblock--xsmall-bleed & {
        width: 100%;
      }

      @media print {
        width: $printWidth;
      }

      &:after {
        bottom: -6px;
        content: '';
        display: block;
        left: 0;
        background: $textBg;
        height: $textHeight;
        position: absolute;
        width: 87px;

        [dir='rtl'] & {
          left: auto;
          right: 0;
        }

        @include breakpoint(large) {
          bottom: -16px;
        }
      }
    }

    &--xsmall {
      float: left;
      margin: 0 rem-calc(14) rem-calc(14) 0;
      width: 48.387%;

      [dir='rtl'] & {
        float: right;
        margin: 0 0 rem-calc(14) rem-calc(14);
      }

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        margin: 0 rem-calc(30) rem-calc(20) 0;

        [dir='rtl'] & {
          margin: 0 0 rem-calc(20) rem-calc(30);
        }
      }
    }

    &--xsmall-reverse {
      float: right;
      margin: 0 0 rem-calc(14) rem-calc(14);
      width: 48.387%;

      [dir='rtl'] & {
        float: left;
      }

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        margin: 0 0 rem-calc(20) rem-calc(30);

        [dir='rtl'] & {
          margin: 0 rem-calc(30) rem-calc(20) 0;
        }
      }
    }

    &--small-bleed,
    &--xsmall-bleed,
    &--mini-bleed {
      .regional-container & {
        background: #fff;
        padding: rem-calc(20) 0;
      }
    }

    &--xsmall-bleed {
      float: left;
      width: 100%;

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        float: left;
        margin: 0 30px 30px -17.1774%;
        width: 48.387%;

        [dir='rtl'] & {
          float: right;
          margin: 0 -17.1774% 30px 30px;
        }
      }

      @include breakpoint(large) {
        margin: 0 30px 30px -34.3548%;
        width: 48.387%;

        [dir='rtl'] & {
          margin: 0 -34.3548% 30px 30px;
        }
      }
    }

    &--small-bleed {
      float: left;
      width: 100%;

      @media print {
        width: $printWidth;
        margin: $printMargin !important;
      }

      @include breakpoint(medium) {
        float: left;
        margin: 0 rem-calc(30) rem-calc(30) -17.1774%;
        width: 48.387%;

        [dir='rtl'] & {
          float: right;
          margin: 0 -17.1774% rem-calc(30) rem-calc(30);
        }
      }

      @include breakpoint(large) {
        margin: 0 rem-calc(30) rem-calc(40) -34.3548%;
        width: 65%;

        [dir='rtl'] & {
          margin: 0 -34.3548% rem-calc(40) rem-calc(30);
        }

        .regional-container & {
          background: #fff;
          padding: rem-calc(20) 0;
        }
      }
    }

    &--mini-bleed {
      float: left;
      margin: 0 rem-calc(14) rem-calc(14) 0;
      width: 50%;

      [dir='rtl'] & {
        float: right;
        margin: 0 0 rem-calc(14) rem-calc(14);
      }

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        float: left;
        margin: 0 rem-calc(30) rem-calc(30) -17.1774%;
        width: 48.387%;

        [dir='rtl'] & {
          float: right;
          margin: 0 -17.1774% rem-calc(30) rem-calc(30);
        }
      }

      @include breakpoint(large) {
        margin: 0 0 rem-calc(40) -34.3548%;
        width: 31.129%;

        [dir='rtl'] & {
          margin: 0 -34.3548% rem-calc(40) 0;
        }

        .regional-container & {
          background: #fff;
          padding: rem-calc(20) 0;
        }
      }
    }

    &--mini {
      float: left;
      margin: 0 rem-calc(14) rem-calc(14) 0;
      width: 50%;

      [dir='rtl'] & {
        float: right;
        margin: 0 0 rem-calc(14) rem-calc(14);
      }

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        margin: 0 rem-calc(30) rem-calc(30) 0;

        [dir='rtl'] & {
          margin: 0 0 rem-calc(30) rem-calc(30);
        }
      }

      @include breakpoint(large) {
        margin: 0 rem-calc(30) rem-calc(40) 0;
        width: 31.129%;

        [dir='rtl'] & {
          margin: 0 0 rem-calc(40) rem-calc(30);
        }
      }
    }

    &--mini-reverse {
      float: right;
      margin: 0 0 rem-calc(14) rem-calc(14);
      width: 50%;

      [dir='rtl'] & {
        float: left;
        margin: 0 rem-calc(14) rem-calc(14) 0;
      }

      @media print {
        width: $printWidth;
      }

      @include breakpoint(medium) {
        margin: 0 0 rem-calc(30) rem-calc(30);

        [dir='rtl'] & {
          margin: 0 rem-calc(30) rem-calc(30) 0;
        }
      }

      @include breakpoint(large) {
        margin: 0 0 rem-calc(40) rem-calc(30);
        width: 31.129%;

        [dir='rtl'] & {
          margin: 0 rem-calc(30) rem-calc(40) 0;
        }
      }
    }

    &__image {
      margin: 0;
    }

    &__zoom {
      &__trigger {
        display: block;
        position: relative;
        transition: all 0.3s ease-in;
        text-decoration: none;
        @include icon('e80e');

        @media print {
          background-image: none;
        }

        &:before {
          color: #fff;
          position: absolute;
          text-decoration: none;
          transition: all 0.4s cubic-bezier(0, 0, 0.04, 1.57);
          z-index: 2;
          margin-right: 1px;

          @media print {
            display: none;
          }
        }
        &:after {
          border-radius: 100px;
          color: #fff;
          content: '';
          display: block;
          height: rem-calc(40);
          position: absolute;
          right: $zoomRight;
          top: 10px;
          transition: all 0.1s ease-in;
          width: rem-calc(40);
          z-index: 1;
          background-color: $zoomBg;

          @media print {
            display: none;
          }
        }

        &:hover {
          &:after {
            background-color: $hoverBg;
          }

          &:before {
            transform: scale(1.2);

            [dir='rtl'] & {
              transform: scale(1.2) scaleX(-1);
            }
          }
        }
      }

      &__overlay {
        height: 0;
        overflow: hidden;
        position: absolute;
        transition: background-color 0.2s ease;
        visibility: hidden;
        width: 0;
        z-index: 100000;

        img {
          display: none;
        }

        &--zoomed {
          background: #000;
          bottom: 0;
          left: 0;
          height: 100vh;
          overflow-y: scroll;
          position: fixed;
          visibility: visible;
          right: 0;
          top: 0;
          width: 100%;
          -webkit-overflow-scrolling: touch;

          @include breakpoint(large) {
            background: rgba(#000, 0.74);
          }

          img {
            display: inline-block;
          }
        }
      }

      &__image {
        background: #fff;
        left: 50%;
        max-width: rem-calc(1180);
        height: 100vh;
        outline: none;
        position: absolute;
        transition: all 0.3s ease;
        transform: translateX(-50%) scale(0);
        top: 0;
        width: 100%;

        @include breakpoint(large) {
          height: auto;
          margin-bottom: 30px;
          padding: rem-calc(16);
          top: 30px;
        }

        & > div {
          height: 100%;

          @include breakpoint(large) {
            height: auto;
          }
        }

        &__inner {
          margin: 0;
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          @include breakpoint(large) {
            top: auto;
            transform: none;
          }
        }

        &__close {
          @include icon('e810');
          border-radius: 100px;
          content: '';
          cursor: pointer;
          display: block;
          height: rem-calc(40);
          position: absolute;
          transition: all 0.1s ease;
          width: rem-calc(40);
          right: 10px;
          top: 10px;
          background-color: $zoomCloseBg;
          color: $zoomCloseColor;
          font-size: $zoomCloseFontSize;

          &:before {
            transition: all 0.4s cubic-bezier(0, 0, 0.04, 1.57);
            margin-top: -12.5px;
            margin-left: -9px;
            top: 50%;
            left: 50%;
            position: absolute;
            width: 20px;

            [dir='rtl'] & {
              margin-left: 0;
            }
          }

          &:hover {
            background-color: $hoverBg;
          }
        }

        .c-imageblock__zoom__overlay--zoomed & {
          transform: translateX(-50%) scale(1);
        }
      }

      &__text {
        padding-top: rem-calc(10);
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
        position: relative;

        @include breakpoint(medium) {
          width: 80%;
        }

        @include breakpoint(large) {
          color: $descriptionColor;
          padding-left: 0;
          padding-right: 0;
          width: 65%;
        }
      }
    }

    &--is-sensitive {
      @media print {
        padding: 0;
      }
    }

    &--is-intro {
      padding-top: 66.6667%;
      position: relative;
      img {
        display: none;
      }

      .c-image {
        bottom: 0;
        background-size: cover;
        background-repeat: no-repeat;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  html {
    &.is-img-zoom {
      overflow: hidden;
    }
  }

  .c-image {
    overflow: hidden;
    opacity: 1;

    img {
      animation: imgLoading 3s linear 4s infinite;
      filter: blur(15px);
      transition: filter 0.15s ease;
      width: 100%;
    }

    &--is-lazyloaded {
      img {
        animation: none;
        filter: blur(0);
      }
    }

    noscript img {
      filter: none;
    }
  }
  @content;
}
