@import '../../platformui/Breadcrumb/styles.scss';

@include Breadcrumb($textColor: c('stone-dark')) {
  font-size: rem-calc(16);
  line-height: 1.5;

  @media print {
    display: none;
  }

  ol {
    padding-bottom: 25px;
  }

  a {
    @include focus-handler();
  }
}
