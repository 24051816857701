@import './footer-linklist-vardpersonal.scss';

@import '../../platformui/Framework/platform-mixins/Chevron.scss';
@import '../../platformui/Framework/platform-mixins/CookieProSettings.scss';

.vardpersonal-footer {
  @include Chevron(
    $className: c-collapsible-panel__button__icon,
    $chevronColor: c('sky-base'),
    $height: 40px,
    $top: 13px
  ) {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    @include focus-handler();
  }

  position: relative;
  z-index: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  transition: opacity 0.15s ease;

  .c-linklist--site-footer {
    .c-linklist__link {
      display: inline-flex;
      color: c('white');
    }
  }

  &__content {
    background-color: c('sky-background');
    padding: var(--56, 56px) var(--88, 88px);

    @include breakpoint(small, medium) {
      display: none;
    }

    @include breakpoint(large) {
      display: block;
    }
  }

  &__heading {
    color: c('white');
    font-weight: bold;
    padding-right: 5px;
    letter-spacing: normal;
    font-size: 24px;
    margin-block-start: 0;
    text-align: center;

    @include breakpoint(large) {
      font-size: 1.5rem;
    }
  }

  &__subheading {
    color: c('white');
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    row-gap: 30px;

    @include breakpoint(large) {
      margin: 0;
    }
  }

  &__site {
    padding: var(--48, 48px) var(--40, 40px);

    @include breakpoint(small) {
      background-color: c('sky-base');
    }

    @include breakpoint(large) {
      display: block;
      background-color: c('sky-base');
      padding: var(--48, 48px) var(--88, 88px);
    }

    @include CookieProSettings(
      $color: c('sky-base'),
      $secondaryColor: c('white'),
      $focusColor: c('grey-500'),
      $secondaryFocusColor: c('white'),
      $fontSize: 0.875rem
    );

    &__inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      gap: 0.625rem;

      &__editor {
        @include breakpoint(large) {
          color: c('white');
          max-width: 618px;
        }

        p,
        a {
          color: c('white');
          font-size: 0.875rem;
          line-height: 1.5;
          min-height: auto;
          margin-bottom: 0;
        }
      }

      &--with-siteinformation {
        flex-direction: row;
        justify-content: space-between;

        & p,
        a {
          color: c('white');
          font-size: 0.875rem;
          margin-bottom: 0;
          margin-top: 20px;
        }
      }
    }
  }

  &__error-logo {
    height: 56px;
    margin: 40px auto 50px;
    width: 150px;
    display: block;
    object-fit: contain;
  }

  &__collapsible-lists {
    padding: 40px 20px;

    .c-collapsible-panel {
      &__button {
        &__inner {
          padding: 4px 0;
          color: c('sky-base');
          border-bottom: 1px solid c('sky-base');
          width: 100%;
        }
      }

      &__panel {
        background-color: c('sky-background');
        
        li {
          padding: 13px 0;
          box-shadow: none;
          border-bottom: 1px solid c('sky-base');
          
          a {
            padding-left: 10px;
            margin: 0;
            color: c('sky-base');
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
