@import '../../platformui/AnchorNavMultiLevel/styles';
@import '../../platformui/AnchorNavMultiLevel/mobile';

@include AnchorNavMultiLevel(
  $fontColor: c('sky-base'),
  $activeBg: c('sky-background'),
  $borderColor: c('sky-line'),
  $activeBorderColor: c('sky-base')
) {
  .anchor-nav-multi {
    a {
      text-decoration: none;
      &:focus {
        outline: 2px solid c('black');
        outline-offset: -2px;
      }
    }
  }
}

@include AnchorNavMultiLevelMobile(
  $fontColor: c('sky-base'),
  $activeBg: c('white'),
  $borderColor: c('sky-line'),
  $activeBorderColor: c('sky-base')
) {
  .anchor-nav-multi {
    a {
      text-decoration: none;
      &:focus {
        outline: 2px solid c('black');
        outline-offset: -2px;
      }
    }
  }
}
