@mixin CollapsiblePanel(
  $className: 'c-collapsible-panel',
  $btnBoxShadow: 0 -2px 10px 0 rgba($black, 0.4),
  $iconContainerBg: salmon,
  $iconContainerTop: 0,
  $iconContainerRight: 0,
  $iconContainerWidth: 20px,
  $iconPosition: 0,
  $outline: 1px solid red,
  $outlineOffset: 10px
) {
  .#{$className} {
    &__button {
      cursor: pointer;
      padding: 0;
      text-align: inherit;
      width: 100%;
      box-shadow: $btnBoxShadow;

      &:focus {
        outline: 0;
        .c-collapsible-panel__button__icon-container {
          outline: $outline;
          outline-offset: $outlineOffset;
        }
      }

      &__inner {
        display: block;
        margin: 0;
        padding: 16px rem-calc(20);
        position: relative;

        @include breakpoint(medium) {
          max-width: none;
          margin-left: auto;
          margin-right: auto;
          width: 66.66667%;
        }

        @include breakpoint(large) {
          max-width: none;
          margin: 0;
          padding: 16px rem-calc(9);
          width: 100%;
        }
      }

      &__icon-container {
        display: block;
        position: absolute;
        background: $iconContainerBg;
        top: $iconContainerTop;
        right: $iconContainerRight;
        width: $iconContainerWidth;

        [dir='rtl'] & {
          right: auto;
          left: $iconContainerRight;
        }
      }

      &__icon {
        display: block;
        position: absolute;
        left: $iconPosition;
        top: $iconPosition;

        &:before {
          position: absolute;
        }
      }
    }

    &__panel {
      background: $white;
      display: none;
      .js-off & {
        display: block;
      }

      &--is-expanded {
        display: block;
      }
    }

    @content;
  }
}
