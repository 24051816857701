@mixin AnchorNavMultiLevelMobile(
  $fontColor: black,
  $activeBg: #f1f1f5,
  $borderColor: #dadbdc,
  $activeBorderColor: blue
) {
  .anchor-nav-multi {
    max-height: 100%;
    padding-bottom: 30px;
    width: 100%;

    &__navigation {
      padding: 0;

      .children {
        padding-left: 10px;
      }

      h2,
      h3,
      h4 {
        margin-bottom: 0;
      }
      &__heading {
        border-bottom: solid 1px $borderColor;
        font-size: 1.25rem;
        margin: 0;
        margin-bottom: 0;
        padding-bottom: 12px;
      }

      ul {
        padding: 0;
        list-style: none;
        margin: 0;
      }

      li {
        border-bottom: 1px solid $borderColor;
        &:hover {
          cursor: pointer;
          a:hover {
            border-left: 6px solid $activeBorderColor;
          }
        }
      }

      .current {
        border-left: 6px solid $activeBorderColor;
        background-color: $activeBg;
      }

      a {
        display: block;
        padding: 12px 38px 12px 4px;
        min-height: 48px;
        border-left: 6px solid transparent;
        text-decoration: none;
        overflow-wrap: break-word;
      }

      &__first-level,
      &__second-level,
      &__third-level {
        position: relative;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        display: block;

        &__section__items {
          display: none;
          &--opened {
            display: block;
          }
        }
      }

      &__first-level {
        &:has(.current) {
          .anchor-nav-multi__navigation__first-level__section__items {
            display: block;
          }
        }
      }

      &__second-level {
        position: relative;
        &:has(.current) {
          .anchor-nav-multi__navigation__second-level__section__items {
            display: block;
          }
        }
        a:first-of-type {
          border-top: 1px solid $borderColor;
        }
        a {
          font-size: 0.875rem;
          padding-left: 22px;
          &:not(.current) {
            border-left: 6px solid $borderColor;
          }
        }
      }

      &__third-level {
        a {
          font-size: 0.875rem;
          padding-left: 44px;
        }
      }

      &--is-expanded {
        max-height: 1000px;
      }

      &--is-closed {
        max-height: 48px;
      }

      &__expand-btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;
        right: 0px;
        padding: 0;
        background-color: transparent;
        height: 48px;
        width: 48px;
        cursor: pointer;

        &:before {
          content: '';
          height: 10px;
          width: 10px;
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.45327 7.45327C5.20294 7.70361 4.79706 7.70361 4.54673 7.45327L0.187752 3.0943C-0.0625839 2.84396 -0.0625839 2.43809 0.187752 2.18775C0.438088 1.93742 0.843963 1.93742 1.0943 2.18775L5 6.09345L8.9057 2.18775C9.15604 1.93742 9.56191 1.93742 9.81225 2.18775C10.0626 2.43809 10.0626 2.84396 9.81225 3.0943L5.45327 7.45327Z' fill='%23396291'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
        }

        /*           &--is-expanded {
            &:before {
              transform: rotate(180deg);
              transition: transform 0.2s ease-in-out;
            }
          } */
      }
    }
    .anchor-nav-multi__navigation__first-level.anchor-nav-multi__navigation--is-closed {
      max-height: 48px;
    }

    @content;
  }
}
