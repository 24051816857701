@mixin TooltipModal(
  $className: 'tooltip-modal',
  $pseudoIcon: before,
  $buttonFloat: right,
  $containerWidth: (
    calc(100% - 20px),
    calc(50% - 20px),
  ),
  $modalPosition: (
    15px,
    0,
    0,
  ),
  // button arrow and panel position under icon $modalPositionTop: 42px,
  $modalPositionTop: 42px,
  $modalCloseBtnColor: #000,
  $modalBtnColorOpen: #000,
  $scrollbarColor: #000,
  $conditions: #333,
  $focusColor: #333,
  $scrollbarBg: #ccc,
  $ctaColor: #000,
  $buttonBackgroundColor: transparent,
  $modalPanelLMaxWidth--large: 400px,
  $modalPanelHeight: 320px,
  $useFadeOut: true,
  $openButtonArrowTop: 19px,
  $modalPanelBoxShadow: 0 0 20px 0 rgba(0, 0, 0, 0.5)
) {
  $smallPanel: '.#{$className}__modal-panel--small';

  .#{$className} {
    &__modal-container {
      position: absolute;
      width: nth($containerWidth, 1);

      @include breakpoint(medium) {
        width: nth($containerWidth, 2);
      }
    }

    &__modal-open {
      cursor: pointer;
      margin-right: 4px;
      background-color: $buttonBackgroundColor;
      float: $buttonFloat;

      span {
        @include icon('e859', $pseudo: #{$pseudoIcon});

        &:#{$pseudoIcon} {
          font-size: 27px;
          color: $ctaColor;
        }
      }

      &:focus {
        outline: none;
      }

      &:#{$pseudoIcon} {
        font-size: 27px;
        color: $modalBtnColorOpen;
      }

      &--open {
        &:after {
          content: '';
          position: absolute;
          top: $openButtonArrowTop;
          right: nth($modalPosition, 1);
          z-index: 15;
          margin-left: -5px;
          border-width: 12px;
          border-style: solid;
          border-color: transparent transparent $white transparent;
        }
      }

      &:hover,
      &:focus,
      &:active,
      &--open {
        span {
          @include icon('e853', $pseudo: #{$pseudoIcon});
        }
      }
    }

    &__modal-close {
      color: darken($modalCloseBtnColor, 10%);
      cursor: pointer;
      position: absolute;
      background-color: transparent;
      right: 14px;
      top: 17px;
      padding: 0;
      z-index: 30;
      @include icon('e84e');
      @include focus-handler($color: $focusColor, $offset: 2px);

      &:before {
        font-size: 30px;
        margin: 0;
      }
    }

    &__modal-panel {
      position: absolute;
      top: $modalPositionTop;
      right: nth($modalPosition, 2);
      background-color: $white;
      width: 100%;
      height: $modalPanelHeight;
      z-index: 10;
      border-radius: 10px;
      box-shadow: $modalPanelBoxShadow;
      padding: 20px 19px 15px 0;

      @include breakpoint(large) {
        max-width: $modalPanelLMaxWidth--large;
        right: nth($modalPosition, 3);
        width: 85%;
      }

      &--small {
        height: auto;
        width: 95%;
        max-width: 450px;
        padding: 20px 19px 20px 0;
      }

      &#{$smallPanel} {
        &:after {
          background: none;
          height: 0;
        }
      }
      @if $useFadeOut {
        &:after {
          bottom: 15px;
          content: '';
          display: block;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.3) 17%,
            rgba(255, 255, 255, 1) 57%
          );
          height: 40px;
          position: absolute;
          left: 19px;
          right: 39px;
        }
      }

      &__content {
        height: 100%;
        overflow: auto;
        padding: 0 25px 20px 25px;
        position: relative;
        width: 100%;

        #{$smallPanel} & {
          padding: 0 25px;
        }

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 20px;
          height: 14px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 12px;
          border: 3px solid transparent;
          background: $scrollbarColor;
          background-clip: content-box;
        }

        &::-webkit-scrollbar-track {
          background: $scrollbarBg;
          border-radius: 10px;
          margin: 33px 10px 0;

          @include breakpoint(large) {
            margin: 33px 0 0;
          }
        }
      }

      &__conditions {
        color: $conditions;
        display: inline-block;
        font-size: rem-calc(14);
        padding-bottom: 8px;
      }

      &__description {
        padding-bottom: 40px;
      }

      h4 {
        font-size: rem-calc(16);
        max-width: calc(100% - 30px);
      }
    }
    @content;
  }
}
