@import '../../platformui/Meganav/styles.scss';

@include Meganav($className: 'vardpersonal-meganav',
  $width: $global-width,
  $bgColor: c('white'),
  $boxShadow: 0 4px 12px 0 c('stone-line'),
  $columnGap: (20px,
    30px,
    40px),
  $columns: 4,
  $itemContainerPadding: 3px 0,
  $itemPadding: 5px 10px 5px 25px,
  $borderColor: c('stone-line')) {
  padding: 30px 0 50px;

  &__list {
    &__item {
      border-bottom: solid 1px c('stone-line');

      a {
        display: flex;
        align-items: baseline;
        padding: 5px 0;
        text-decoration: none;

        @include focus-handler($offset: 1px);

        &:before {
          content: url('../../framework/images/arrow-right-blue.svg');
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          padding: 8px;
        }

        &:hover,
        &:focus {
          color: c('sky-dark');

          &:before {
            content: url('../../framework/images/arrow-right-white.svg');
            background: c('sky-dark');
            border-radius: 100%;
          }
        }

        span {
          padding-left: 5px;
        }
      }

      &--is-selected {
        a {
          &:before {
            content: url('../../framework/images/arrow-right-white.svg');
            background: c('sky-base');
            border-radius: 100%;
          }
        }
      }
    }
  }

  &__container {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }
}
