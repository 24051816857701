$dark-grey: #484848;

@mixin ArticleList($className: 'c-article-list',
  $headingColor: #000,
  $vignetteColor: #000,
  $textColor: #000,
  $borderBottom: solid 5px transparent,
  $borderBottomHover: solid 5px #000,
  $borderBottomFocus: $borderBottomHover,
  $imageMarginBottom: (15px,
    15px,
    15px,
  ),
  $contentHeight--gt2: 190px,
  $contentHeight--gt3: 195px,
  $contentHeight--nth2: 165px,
  $maxHeightContentSmall: 140px,
  $headingMargin: 0,
  $imgBorderRadius: 0,
  $lineClamp: 20,
  $textHeight: 200px,
  $mobileLineClamp: 20,
  $mobileTextHeight: 200px,
  $linkIcon: 'f105',
  $linkIconColor: black,
  $vardpersonal-arrow-right: ''

) {
  .#{$className} {
    @include clearfix();
    border-bottom: $borderBottom;
    display: block;
    margin-bottom: 10px;
    padding-bottom: 0;
    position: relative;
    text-align: left;
    text-decoration: none;

    [dir='rtl'] & {
      text-align: right;
    }

    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:hover {
      @include breakpoint(large) {
        border-bottom: $borderBottomHover;
      }
    }

    @if ($borderBottomFocus !=false) {
      &:focus {
        @include breakpoint(large) {
          border-bottom: $borderBottomFocus;
        }
      }
    }

    &__img-container {
      margin-bottom: nth($imageMarginBottom, 1);
      overflow: hidden;
      padding-top: 66.6667%;
      position: relative;

      @include breakpoint(medium) {
        margin-bottom: nth($imageMarginBottom, 2);
      }

      @include breakpoint(large) {
        margin-bottom: nth($imageMarginBottom, 3);
      }

      &>div {
        background-color: rgba($black, 0.05);
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: $imgBorderRadius;
      }
    }

    &__img {
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__content {
      max-height: $maxHeightContentSmall;
      padding-bottom: 0;
      padding: 2px;

      @include breakpoint(large) {
        max-height: none;

        .c-article-list--gt2 & {
          height: $contentHeight--gt2;
        }

        .c-article-list--gt3 & {
          height: $contentHeight--gt3;
        }

        .c-article-list--nth2 & {
          height: $contentHeight--nth2;
        }

      }
    }

    &__text {
      color: $textColor;
      font-size: 1rem;

      p {
        font-size: inherit;
        display: -webkit-box;
        -webkit-line-clamp: $mobileLineClamp;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: $mobileTextHeight;

        @include breakpoint(large) {
          -webkit-line-clamp: $lineClamp;
          height: $textHeight;
        }
      }
    }

    &__vignette {
      color: $vignetteColor;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__vardpersonal__icon-arrow-right-blue {
      background-image: $vardpersonal-arrow-right;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      width: 14px;
      height: 12px;
      margin: 2px;
    }

    &__vardpersonal__link {
      text-decoration: none;
      @include focus-handler();

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &__link {
      text-decoration: none;
      display: block;

      @include icon($linkIcon);
      @include focus-handler();

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:before {
        width: auto;
        color: $linkIconColor;
        text-decoration: none;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }

    &__heading {
      color: $headingColor;
      overflow-wrap: anywhere;
      margin-bottom: $headingMargin;
      font-size: 1.375rem;
    }

    .c-image {
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      overflow: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;

      &--is-lazyloaded {
        opacity: 1;
      }

      @include breakpoint(medium) {
        background-size: cover;
        background-position: center top;
      }

      img {
        display: none;
      }
    }

    &--full-layout {
      border-bottom: $borderBottomHover;
      border-bottom-color: transparent;
      padding-bottom: 20px;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint(large) {
        .c-article-list__content-wrapper:after {
          border-bottom: $borderBottom;
        }

        &:hover {
          .c-article-list__content-wrapper:after {
            border-bottom: $borderBottomHover;
          }
        }
      }

      .c-article-list--center {
        justify-content: center;
      }

      .c-article-list__img-container {
        @include breakpoint(medium) {
          margin-bottom: 0;
        }
      }

      .c-article-list__content-wrapper {
        @include breakpoint(large) {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .c-article-list__text {
        color: $textColor;
        height: fit-content;
      }

      .c-image {
        margin-bottom: 0;
      }

      &:after {
        background-image: none;
        height: auto;
      }
    }

    @content;
  }
}
