.logo {
  display: block;
  position: relative;
  z-index: 3;

  @include breakpoint(large) {
    display: inline-block;
    height: 111px;
  }

  @media print {
    height: auto;
    display: block;
  }

  &__svg {
    height: 100%;
    width: 100%;

    @media print {
    }
  }

  &__inner {
    height: 100%;
    position: relative;

    @include breakpoint(large) {
      padding-right: 30px;
      transition: width 0.2s ease;
    }

    @media print {
      background-color: transparent;
    }
  }

  &__link {
    display: block;
    position: relative;
    top: 8px;
    z-index: 2;
    width: 60px;

    @include focus-handler();

    @include breakpoint(large) {
      height: 72px;
      top: 32px;
      width: 120px;
    }

    @media print {
      top: auto;
    }
  }
}
