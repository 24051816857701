@mixin AnchorNavMultiLevel(
  $fontColor: black,
  $activeBg: #f1f1f5,
  $borderColor: #dadbdc,
  $activeBorderColor: blue
) {
  .anchor-nav-multi-track {
    &__wrapper {
      &--is-closed {
        display: none;
      }
      &--is-open {
        background-color: c('white');
        padding: 0;
        z-index: 999;
        .anchor-nav-multi-track__container {
          padding-top: 0;
        }
      }
      @include breakpoint(large) {
        display: block;
        margin-left: auto;
      }
    }
    &__container {
      display: flex;
      flex-flow: column;
      height: 100%;
      position: relative;
      width: 100%;
      padding-top: 50px;
    }

    @include breakpoint(medium) {
      display: block;
      flex: 1 1 auto;
      position: relative;
      margin-top: -30px;
      padding-top: 30px;
    }
    @media print {
      display: none;
    }

    .anchor-nav-multi {
      max-height: 100%;
      padding-bottom: 30px;
      width: 100%;

      @include breakpoint(large) {
        display: block;

        &:before {
          background: #fff;
          box-shadow: -2px 2px 2px rgba(#000, 0.15);
          content: '';
          display: block;
          height: 22px;
          position: absolute;
          left: -9px;
          top: 49px;
          width: 22px;
          transform: rotate(45deg);
        }
      }

      @media print {
        display: none;
      }

      &--is-fixed {
        max-width: 300px;
        position: fixed;
        top: 80px;

        &:before {
          top: 18px;
        }
      }

      &--is-hidden {
        display: none;
      }

      &__inner {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
        @include breakpoint(large) {
          border-radius: 10px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
        }
        overflow: hidden;

        .anchor-nav--is-fixed & {
          width: calc(25% - 20px);
        }
      }

      &__navigation {
        padding: 15px 20px 20px;

        .children {
          padding-left: 10px;
        }

        h2,
        h3,
        h4 {
          margin-bottom: 0;
        }
        &__heading {
          border-bottom: solid 1px $borderColor;
          font-size: 1.25rem;
          margin: 0;
          margin-bottom: 0;
          padding-bottom: 12px;
        }

        &__list{
          max-height: 80vh;
          overflow-y: auto;
        }

        ul {
          padding: 0;
          list-style: none;
          margin: 0;
        }

        li {
          border-bottom: 1px solid $borderColor;
          &:hover {
            cursor: pointer;
            a:hover {
              border-left: 6px solid $activeBorderColor;
            }
          }
        }

        .current {
          border-left: 6px solid $activeBorderColor;
          background-color: $activeBg;
        }

        a {
          display: block;
          padding: 12px 38px 12px 4px;
          min-height: 48px;
          border-left: 6px solid transparent;
          text-decoration: none;
          overflow: hidden;
          @include breakpoint(large) {
            white-space: normal;
          }
        }

        &__first-level,
        &__second-level,
        &__third-level {
          position: relative;
          overflow: hidden;
          transition: max-height 0.5s ease-in-out;
          @include breakpoint(large) {
            max-height: 100%;
          }
          &__section__items {
            display: none;
            &--opened {
              display: block;
            }
          }
          a {
            overflow-wrap: break-word;
          }
        }

        &__first-level {
          &:has(.current) {
            .anchor-nav-multi__navigation__first-level__section__items {
              display: block;
            }
          }
        }

        &__second-level {
          &:has(.current) {
            .anchor-nav-multi__navigation__second-level__section__items {
              display: block;
            }
          }
          @include breakpoint(large) {
            transition: none;
          }
          display: block;
          position: relative;
          a:first-of-type {
            border-top: 1px solid $borderColor;
          }
          a {
            font-size: 0.875rem;
            padding-left: 22px;
            &:not(.current) {
              border-left: 6px solid $borderColor;
            }
          }
        }

        &__third-level {
          a {
            font-size: 0.875rem;
            padding-left: 44px;
          }
        }

        &__expand-btn {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0px;
          right: 0px;
          padding: 0;
          background-color: transparent;
          height: 48px;
          width: 48px;
          cursor: pointer;

          &:before {
            content: '';
            height: 10px;
            width: 10px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.45327 7.45327C5.20294 7.70361 4.79706 7.70361 4.54673 7.45327L0.187752 3.0943C-0.0625839 2.84396 -0.0625839 2.43809 0.187752 2.18775C0.438088 1.93742 0.843963 1.93742 1.0943 2.18775L5 6.09345L8.9057 2.18775C9.15604 1.93742 9.56191 1.93742 9.81225 2.18775C10.0626 2.43809 10.0626 2.84396 9.81225 3.0943L5.45327 7.45327Z' fill='%23396291'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
          }
        }
      }
    }

    @content;
  }
}
