.process-container {
  position: relative;
  margin-bottom: 20px;
  .togglecontent-container {
    background-color: c('sky-background');

    &--level-1 {
      &[open] > .togglecontent-container__summary {
        margin-bottom: 10px;

        & > .togglecontent-container__heading__container {
          margin-bottom: 10px;
          border-bottom: 1px solid c('sky-line');
        }
      }
      & > .togglecontent-container__summary {
        position: relative;
        &:has(.togglecontent-container__open-all) {
          padding-bottom: 25px;
          margin-bottom: 10px;
        }
      }
    }

    &__open-all {
      font-size: 0.875rem;
      color: c('sky-base');
      background-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 10px;
      margin: 0 0 10px auto;

      &--is-open {
        &::after {
          transform: rotate(180deg);
        }
      }

      &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 15px;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.54673 7.96278C4.79706 8.21307 5.20294 8.21307 5.45327 7.96278L9.81225 3.6046C10.0626 3.35431 10.0626 2.94851 9.81225 2.69822C9.56191 2.44793 9.15604 2.44793 8.9057 2.69822L5 6.60321L1.0943 2.69822C0.843963 2.44793 0.438088 2.44793 0.187752 2.69822C-0.062584 2.94851 -0.062584 3.35431 0.187752 3.6046L4.54673 7.96278Z' fill='%23396291'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.54673 12.9628C4.79706 13.2131 5.20294 13.2131 5.45327 12.9628L9.81225 8.6046C10.0626 8.35431 10.0626 7.94851 9.81225 7.69822C9.56191 7.44793 9.15604 7.44793 8.9057 7.69822L5 11.6032L1.0943 7.69822C0.843963 7.44793 0.438088 7.44793 0.187752 7.69822C-0.062584 7.94851 -0.062584 8.35431 0.187752 8.6046L4.54673 12.9628Z' fill='%23396291'/%3E%3C/svg%3E%0A");
        background-size: 10px 15px;
        background-repeat: no-repeat;
        transition: transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .pwt-text {
    &__content {
      ul,
      ol {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-left: 18px;
      }
    }
  }
}
