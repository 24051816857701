@mixin Linklist($className: 'c-linklist',
  $leftItemPadding: 28px,
  $iconTopPosition: 0,
  $iconFontSize: 120%,
  $ulMargin: 0) {
  .#{$className} {
    font-size: inherit;
    margin: 0;
    padding: 0;

    @include Linklist--block();

    ul {
      font-size: inherit;
      list-style: none;
      margin: $ulMargin;
      padding: 0;

      @media print {
        list-style: none !important;
        padding: 20px 0 0;
      }
    }

    li {
      margin-bottom: 8px;
    }

    a {
      text-decoration: none;
      z-index: 2;
      min-height: 24px;

      @media print {
        text-decoration: underline !important;
      }
    }

    button {
      font-family: inherit;
      font-size: inherit;
      background: none;
      color: inherit;
      -webkit-font-smoothing: auto;
    }

    &--white {
      a {
        display: inline-block;

        &:hover {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }
      }
    }

    &--site-footer {
      a {
        display: inline-block;

        &:hover {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }
      }
    }

    &--collapsible {
      li {
        border-bottom: solid 1px rgba($black, 0.1);
        display: block;
        margin-bottom: 0;
        padding: 11px 20px 12px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @include breakpoint(medium) {
          padding: 16px 20px 16px;
        }
      }

      a {
        display: block;

        @include breakpoint(medium) {
          margin-left: auto;
          margin-right: auto;
          width: 66.66667%;
        }
      }

      //Hide header for large if list is collapsible on small
      .c-linklist__header {
        color: inherit;

        @include breakpoint(large) {
          display: none;
        }
      }
    }

    &--inline {
      display: inline-block;
      width: 100%;

      @media print {
        line-height: 16px !important;
      }

      ul {
        @media print {
          padding-left: 0 !important;
          line-height: 16px !important;
        }
      }

      li {
        display: inline-block;

        @media print {
          display: inline-block !important;
        }
      }
    }

    &--spaced {
      li {
        margin-bottom: 12px;
      }
    }

    &__link {
      display: inline-block;
      padding-left: $leftItemPadding;
      position: relative;

      @media print {
        padding-left: 0 !important;
        margin-bottom: 0 !important;
      }

      &:hover {
        text-decoration: none;
      }

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: 26px;

        @media print {
          padding-right: 0 !important;
        }
      }

      &:before {
        font-size: $iconFontSize;
        position: absolute;
        left: 0;
        top: $iconTopPosition;

        [dir='rtl'] & {
          left: auto;
          right: 0;
          transform: rotateY(180deg);
        }
      }

      //Types of icons
      //Default external

      &:before {
        //content: ''; //Kommenterade bort den här för att externa ikoner försvann från umo/youmo
        margin-right: 10px;

        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      &[href$='.pdf'],
      &--pdf {
        @include icon('e82b', false, false);
      }

      &[href$='.txt'],
      &[href$='.xml'] {
        @include icon('e82a', false, false);
      }

      &[href$='.doc'],
      &[href$='.docx'] {
        @include icon('e833', false, false);
      }

      &[href$='.ppt'],
      &[href$='.pptx'] {
        @include icon('e831', false, false);
      }

      &[href$='.xls'],
      &[href$='.xlsx'] {
        @include icon('e832', false, false);
      }

      &[href$='.jpg'],
      &[href$='.jpe'],
      &[href$='.jpeg'],
      &[href$='.gif'],
      &[href$='.bmp'],
      &[href$='.png'],
      &[href$='.mp3'],
      &[href$='.zip'],
      &[href$='.webp'] {
        @include icon('e830', false, false);
      }

      &[href*='facebook.com'],
      &[href*='facebook.se'] {
        @include icon('e80a', false, false);

        .c-linklist--collapsible & {
          padding-left: 25px;

          [dir='rtl'] & {
            padding-left: 0;
            padding-right: 25px;
          }
        }
      }

      &[href*='youtube.com'] {
        @include icon('f167', false, false);

        .c-linklist--collapsible & {
          padding-left: 22px;

          [dir='rtl'] & {
            padding-left: 0;
            padding-right: 22px;
          }
        }
      }

      &[href^='mailto:'] {
        @include icon('e813', false, false);

        &:before {
          margin-top: 2px;
        }

        .c-linklist--collapsible & {
          padding-left: 22px;

          [dir='rtl'] & {
            padding-left: 0;
            padding-right: 22px;
          }
        }
      }

      &[href*='instagram.com'],
      &[href*='instagram.se'] {
        @include icon('e814', false, false);

        .c-linklist--collapsible & {
          padding-left: 22px;

          [dir='rtl'] & {
            padding-left: 0;
            padding-right: 22px;
          }
        }
      }

      //overrides

      .c-linklist--white & {
        &:before {
          color: $white;
        }
      }
    }
  }

  @content;
}

@mixin LinklistLink($className: 'c-linklist',
  $icon: 'E820',
  $iconPdf: 'e82b',
  $iconDoc: 'e833',
  $iconXls: 'e832',
  $iconPpt: 'e831',
  $iconFile: 'e867',
  $iconImg: 'e867',
  $printColor: black,
  $iconFontSize: rem-calc(10),
  $iconTopMargin: 7px,
  $iconColor: inherit,
  $documentColor: $iconColor) {
  .#{$className}__link {
    @include focus-handler();
    @include icon(#{$icon});

    &[href$="/"],
    &[href^="/"],
    &[href*="/#"],
    &[href*="tel:"],
    &[href*="mailto:"],
    &[href*="http://"],
    &[href*="https://"],
    &[href$='.jpg'] {
      &:before {
        font-size: $iconFontSize;
        top: $iconTopMargin;
        color: $iconColor;
      }
    }

    &[href$='.pdf'],
    &[href$='.doc'],
    &[href$='.docx'],
    &[href$='.dotx'],
    &[href$='.xls'],
    &[href$='.xlsx'],
    &[href$='.csv'],
    &[href$='.ppt'],
    &[href$='.pptx'],
    &[href$='.pps'],
    &[href$='.txt'],
    &[href$='.jpg'],
    &[href$='.jpeg'],
    &[href$='.jpe'],
    &[href$='.ico'],
    &[href$='.gif'],
    &[href$='.bmp'],
    &[href$='.png'],
    &[href$='.zip'] {
      &:before {
        font-size: 18px;
        top: 3px;
        color: $documentColor;
      }
    }

    &[href$='.pdf'] {
      @include icon(#{$iconPdf});
    }

    &[href$='.doc'],
    &[href$='.docx'],
    &[href$='.dotx'] {
      @include icon(#{$iconDoc});
    }

    &[href$='.xls'],
    &[href$='.xlsx'],
    &[href$='.csv'] {
      @include icon(#{$iconXls});
    }

    &[href$='.ppt'],
    &[href$='.pptx'],
    &[href$='.pps'] {
      @include icon(#{$iconPpt});
    }

    &[href$='.txt'],
    &[href$='.zip'] {
      @include icon(#{$iconFile});
    }

    &[href$='.jpg'],
    &[href$='.jpeg'],
    &[href$='.jpe'],
    &[href$='.gif'],
    &[href$='.bmp'],
    &[href$='.png'],
    &[href$='.ico'] {
      @include icon(#{$iconImg});
    }

    &:before {
      @media print {
        top: 3px !important;
        text-decoration: none;
      }
    }

    .c-linklist--no-icons & {
      &:before {
        content: '';
        display: none;
      }
    }

    span {
      display: inline;
      line-height: 1.4;

      @media print {
        border: none;
        color: $printColor !important;
        padding-left: 20px;
      }

      .info-box & {
        border: none;
        line-height: 1.5;
      }
    }

    @content;
  }
}

@mixin Linklist--block($marginBottom: 30px) {
  &--block {
    margin-bottom: $marginBottom;
    clear: both;
    @content;
  }
}
