@import '../../platformui/Linklist/styles.scss';

@include Linklist($className: 'error404-linklist') {
  .error404-linklist {

    @include LinklistLink(
      $className: 'error404-linklist',
      $printColor: c('denim')
    );

    &__list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      @include breakpoint(medium) {
        gap: 40px;
        flex-direction: row;
      }
      
      span {
        text-decoration: underline;
      }
    }
  }
}
