@import '../../platformui/Linklist/styles.scss';

$infobox: '.info-box';

@include Linklist($leftItemPadding: 25px, $iconTopPosition: 3px) {
  .c-linklist {
    &--collapsible {
      li {
        border-bottom: none;
        box-shadow: inset 0 2px 4px 0 rgba(c('black'), 0.2);
      }
    }

    &__list {
      li {
        &[dir='rtl'] {
          text-align: right;
        }
        // remove padding in chapter block
        .c-chapter & {
          padding: 0;
        }
      }
    }

    &__header {
      font-weight: bold;
      text-transform: none;
      line-height: 1.2;
      font-size: 20px;
      position: relative;
      margin-bottom: 10px;

      @media print {
        margin-bottom: 10px;
      }
      // override due to chapter styles for heading.
      .c-chapter & {
        color: c('black');
      }
    }

    a {
      z-index: 0;
    }

    @include LinklistLink($iconImg: 'e890');
  }
}
