.error500 {
  min-height: 100vh;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  padding: 0;

  .preamble {
    &--alt {
      font-size: 1.5rem;
    }
  }

  .c-articleheader__heading {
    text-align: center;

    &--alt {
      font-size: 1.5rem;
    }
  }

  .row.first-row {
    position: relative;
  }

  &__footer {
    margin-bottom: 0px;
    padding-top: 60px;

    @include breakpoint(large) {
      margin-bottom: 260px;
    }

    .nkk-header {
      color: c('secondary-dark');
      font-weight: bold;
      padding-right: 5px;
      letter-spacing: normal;
      text-align: center;
      font-size: 0.875rem;
      @include breakpoint(medium) {
        font-size: 1.5rem;
      }
    }
  }
}
