@import '../../platformui/ArticleList/styles';

@include ArticleList($headingColor: c('denim'),
  $textColor: $black,
  $borderBottomHover: solid 5px rgba(c('denim'), 0.5),
  $borderBottomFocus: false,
  $imageMarginBottom: (15px,
    15px,
    20px,
  ),
  $contentHeight--gt2: 100%,
  $contentHeight--gt3: 100%,
  $contentHeight--nth2: 100%,
  $maxHeightContentSmall: 100%,
  $headingMargin: 10px,
  $lineClamp: 3,
  $textHeight: 100%,
  $mobileLineClamp: 2,
  $mobileTextHeight: 100%,
  $linkIconColor: c('sky-base'),
  $vardpersonal-arrow-right: url('../../framework/images/arrow-right-blue.svg')) {
  @include focus-handler($color: c('grey-500'), $offset: 8px);

  &__heading {
    @include secondary-font-family();
    font-weight: bold;
    line-height: 1.25;
    margin: 0 0 5px;
  }

  &__content-wrapper {
    @include breakpoint(medium) {
      padding-right: 15px;
    }

    &:after {
      background-image: none;

      .c-section--blue & {
        background-image: none;
      }

      .c-section--grey & {
        background-image: none;
      }
    }
  }

  &__content {
    max-height: unset;
  }
}
