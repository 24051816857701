.c-lists-puff {
  padding-block: 20px;

  &__inner {
    padding: 30px 30px 40px 30px;
    background-color: c('secondary');
    border-radius: 10px;

    h2 {
      color: c('sky-base');
      border-bottom: 1px solid c('stone-line');
      margin: 0;
      padding-block-end: 1rem;
    }

    .c-linklist-puff {

      ul {
        display: flex;
        row-gap: 29px;
        column-gap: 33px;
        flex-direction: row;
        padding-block-start: 1.5rem;
        flex-wrap: wrap;

        @include breakpoint(large) {
          row-gap: 12px;
        }

        li {
          margin-bottom: 0;
          padding-right: 1rem;
          width: 100%;

          @include breakpoint(medium) {
            flex: 1 0 calc(50% - 33px);
            max-width: calc(50% - 33px);
          }

          @include breakpoint(large) {
            flex: 1 0 calc(25% - 33px);
            max-width: calc(25% - 33px);
          }
        }
      }

      @include LinklistLink() {
        &:before {
          color: c('sky-base');
          text-decoration: none;
          content: '';
        }

        &[href$="/"],
        &[href*="http://"],
        &[href*="https://"] {
          &:before {
            content: '';
            background-image: url('../../framework/images/arrow-right-blue.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 14px;
            height: 12px;
            margin: 2px;
            top: 4px;
          }
        }

        span {
          @include secondary-font-family();
          color: c('sky-base');
          font-weight: bold;
          line-height: 1.25rem;

          &:hover {
            color: c('sky-dark');
            text-decoration: underline;
          }
        }

        i {
          font-size: 0.8rem;
        }
      }
    }
  }
}
