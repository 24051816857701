@import '../../platformui/ToggleContent/styles.scss';
@include ToggleContentContainer($chevronColor: c('denim')) {
  position: relative;
  background-color: c('white');

  + .activity-description {
    &::before {
      content: '';
      top: -10px;
      left: 0px;
      right: 0px;
      height: 1px;
      display: block;
      position: absolute;
      background-color: c('stone-clear');
    }
  }

  &[open] + .activity-description {
    &::before {
      top: -5px;
    }
  }

  h2 {
    color: c('denim');
    font-size: 1.25rem;
    margin: 0;
  }
  h3,
  h4,
  h5,
  h6 {
    @include secondary-font-family();
    color: c('denim');
    font-size: 1rem;
    margin: 0;
  }

  &__summary {
    position: relative;
    border-top: 1px solid c('stone-clear');
    min-height: 40px;

    .chevron--toggle-content {
      margin-left: auto;
      right: -10px;
    }
  }

  .activity-description {
    position: relative;
  }

  &--level-1 {
    padding: 10px;
    border-radius: 5px;
    background-color: c('sky-background');
  }

  &--level-1 > &__summary {
    border-top: none;
  }

  &--level-2 {
    border: 1px solid c('denim');
    border-radius: 5px;
    padding: 5px 20px;
    margin-bottom: 10px;
  }

  &--level-2 > &__summary {
    border-top: none;
  }

  &--level-3 {
    .togglecontent-container {
      .togglecontent-container__summary {
        padding-left: 20px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 6px;
        top: 0;
        bottom: -10px;
        left: -13px;
        background-color: c('sky-line');
      }

      &:last-of-type {
        &:before {
          bottom: 0;
        }
      }
    }
  }

  &--level-4 {
    .togglecontent-container {
      margin-left: 7px;
      &:before {
        left: -12px;
      }
    }
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__container {
      gap: 10px;
    }

    &__prefix {
      display: inline-block;
      flex-shrink: 0;
      width: fit-content;
      text-align: center;
      font-size: 0.875rem;
      font-weight: bold;
      color: c('sky-dark');
      min-width: 22px;
      min-height: 22px;
      border-radius: 50px;
      border: 1px solid c('sky-base');
      background-color: c('sky-background');
      padding: 4px;
    }
  }

  &__content .activity-description {
    background-color: c('sky-background');
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    &__target-header {
      font-size: 1rem;
      margin-top: 0;
      margin-bottom: 10px;
      color: c('black');
    }
  }
}
