@mixin FilterBarFacets(
  $itemColor: black,
  $itemSelectedColor: black,
  $selectedItemBorder: 4px solid red
) {
  .c-facets {
    margin-bottom: 40px;
    padding-bottom: 0;
    text-align: center;

    &__items {
      text-align: left;

      @include breakpoint(large) {
        text-align: center;
      }
    }

    &__item {
      color: $itemColor;
      cursor: pointer;
      display: inline-block;
      font-size: 1.125rem;
      padding: 10px 0px;
      text-decoration: none;
      margin-right: 20px;
      margin-left: 20px;
      &--selected,
      &:focus {
        text-decoration: none;
        outline: none;
      }

      &--selected {
        color: $itemSelectedColor;
        border-bottom: $selectedItemBorder;
        margin-bottom: -4px;
      }

      &__checkbox {
        position: absolute;
        left: -9999px;
      }

      &__label {
        display: block;
      }
    }
    @content;
  }
}
