.link-wrapper {
  margin-bottom: 1rem;
  a {
    @include focus-handler();

    &[href$='.pdf'] {
      @include icon($content: 'E82B', $pseudo: after);
      &:after {
        text-decoration: none;
      }
    }
  }
}
