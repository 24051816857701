@import '../../platformui/CookiePanel/styles';

@include CookiePanel( $buttonBorderRadius: 30px, $backgroundColor: c('main'), $backgroundImage: none, $panelAcceptButtonColor: c('main'), $panelAcceptButtonBgColor: c('white'), $panelMaxWidth: 100%, $panelBoxShadow: none, $panelContentMinHeight: 156px, $panelContentPadding: ( 22px 32px 32px, 22px 32px 32px, 0 32px 12px, ), $panelContentFontSize: ( rem-calc(16), rem-calc(16), rem-calc(20), ), $panelContentFontWeight: 300, $panelContentLinkTextDecoration: none, $panelAcceptButtonFontSize: ( rem-calc(18), rem-calc(18), rem-calc(20), ), $panelAcceptButtonTextTransform: uppercase, $panelAcceptButtonFontWeight: ( bold, bold, 300, ), $panelAcceptButtonHeight: ( 40px, 50px, 50px, ), $panelAcceptButtonMinWidth: ( 173px, 140px, 140px, ) ) {
  &:before {
    background: c('main');
    box-shadow: 0px -2px 10px 1px rgba(0, 0, 0, 0.3);
    content: '';
    height: 150%;
    left: 0;
    position: absolute;
    right: 0;
    top: -13px;
    z-index: 1;
  }

  @include breakpoint(large) {
    padding-top: 0;
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  &__button {
    font-weight: bold;
  }

  &__content__text {
    max-width: 675px;
    text-align: center;

    @include breakpoint(large) {
      max-width: 100%;
      text-align: left;
    }

    a {
      border-bottom: solid 0.5px c('white');
    }
  }
}