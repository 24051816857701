@mixin SearchEntryForm() {
  .entryform-searchform {
    padding: 30px;
    background-color: #f1f1f5;
    border-radius: 10px;
    margin-bottom: 30px;
    position: relative;

    .c-search__field__query {
      @include breakpoint(large) {
        width: calc(100% - 100px);
      }
    }
    .c-search__field__button {
      @include breakpoint(large) {
        width: 164px;
      }
    }

    .c-search__quicksearch {
      position: relative;
      top: 60px;
      left: 12px;
      width: calc(100% - 45px);

      @include breakpoint(large) {
        width: calc(100% - 105px);
        top: 90px;
        left: 30px;
      }
    }
  }
  @content;
}
