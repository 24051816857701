@import '../../platformui/Mobilenav/styles';
@import '../../platformui/Framework/platform-mixins/Chevron.scss';

@include MobileNavWrapper($bgColor: $white);

@include MobileNav($className: 'vardpersonal-mobile',
  $isSelectedBorderLeft: c('sky-base'),
  $fontSize: 1rem,
  $listItemColor: c('sky-base')) {
  font-weight: bold;
  @include secondary-font-family();

  @include Chevron($className: c-expand-button, $chevronColor: c('sky-base')) {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 10px;
    width: 50px;
    @include focus-handler($offset: -8px);
  }

  li.vardpersonal-mobile__list__item:last-child {
    background-color: c('sky-background');

    .switch-icon__link {
      border-bottom: none;
      padding-left: 5px;
    }
  }

  .vardpersonal-mobile__list__item {
    box-shadow: none;

    &:not(:last-child) {
      border-bottom: 1px solid c('sky-base');
    }

    li:first-of-type {
      border-top: 1px solid c('sky-base');
    }

    &--is-open {
      ul>li {
        font-size: 17px;
        border-left-color: c('sky-base');
        font-weight: normal;

        [dir='rtl'] & {
          border-left-color: transparent;
          border-right-color: c('sky-base');
        }

        & div {
          background-color: c('white');
          border-left: 10px solid c('sky-background');
        }
      }

      &.vardpersonal-mobile--selected-item>div {
        border: none;

        a {
          border-left: 10px solid c('sky-base');
        }
      }
    }
  }

  @include MobileNavSubLevel($hasBorder: false,
    $bgColor: c('white'),
    $borderOnHoverColor: c('secondary-light'),
    $listItemBorderLeftWidth: 7px,
    $fontSize: 1rem,
    $textColor: black,
    $sublevelBoxShadow: none) {
    .vardpersonal-mobile {
      &__list__item {
        box-shadow: none;

        &__container {
          box-shadow: 7px 0px 0px c('secondary-light') inset;
        }

        a {
          padding-left: 33px;
          @include focus-handler($offset: -2px);
          font-size: 1rem;
          font-weight: normal;
          color: c('sky-base');
        }
      }

      &--selected-item>.vardpersonal-mobile__list__item__container {
        border-bottom: 1px solid c('secondary-light');
        background-color: c('sky-background');
      }
    }

    @include Chevron($className: c-expand-button,
      $chevronColor: c('sky-base')) {
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      right: 10px;
      width: 50px;
      @include focus-handler($offset: -8px);
    }

    @include SubLevelIndent($textIndent: 53px) {
      // sublevel 2

      @include SubLevelIndent($textIndent: 73px) {
        // sublevel 3
        @include SubLevelIndent($textIndent: 90px) // sublevel 4;
      }
    }
  }
}