@mixin Localnav(
  $className: 'c-localnav',
  $componentMargin: 0 0 20px,
  $borderColor: #000,
  $headingPadding: 14px 20px 15px,
  $headingTextDecoration: none,
  $headingHoverTextDecoration: none,
  $itemColor: inherit,
  $itemHasChildrenIcon: 'f105',
  $itemTextDecoration: none,
  $itemHoverTextDecoration: none,
  $itemPadding: 14px 42px 15px 20px,
  $itemPaddingRtl: 14px 20px 15px 42px,
  $useLeftBorderMarker: true,
  $useBottomBorderMarker: false,
  $borderMarkerWidth: 10px,
  $borderMarkerColor: red,
  $hasChildrenIconColor: yellow,
  $selectedItemBg: transparent,
  $selectedItemColor: red,
  $selectedItemFontWeight: bold
) {
  .#{$className} {
    border-bottom: solid 1px $borderColor;
    margin: $componentMargin;

    @media print {
      display: none;
    }

    &__heading {
      margin: 0;
      a {
        display: block;
        font-weight: bold;
        padding: $headingPadding;
        text-decoration: $headingTextDecoration;
        @include focus-handler();

        &:hover,
        &:focus {
          text-decoration: $headingHoverTextDecoration;
        }
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      [dir='rtl'] & {
        margin: 0;
        padding: 0;
      }

      &__secondary {
        display: none;
        margin: 0;
        padding: 0;

        .c-localnav__list__item--is-open & {
          display: block;
        }

        .c-localnav__list__item {
          a {
            padding-left: 30px;
          }
        }
      }

      &__item {
        border-top: solid 1px $borderColor;
        display: block;
        list-style: none;
        margin: 0;

        & > a {
          color: $itemColor;
          display: block;
          padding: $itemPadding;
          text-decoration: $itemTextDecoration;

          [dir='rtl'] & {
            padding: $itemPaddingRtl;
          }

          @if ($useLeftBorderMarker == true) {
            border-left: solid $borderMarkerWidth transparent;

            [dir='rtl'] & {
              border-left: none;
              border-right: solid $borderMarkerWidth transparent;
            }
          }
          @include focus-handler();
          &:hover,
          &:focus {
            position: relative;
            text-decoration: $itemHoverTextDecoration;

            @if ($useBottomBorderMarker == true) {
              &:after {
                background: $borderMarkerColor;
                bottom: 0;
                content: '';
                height: $borderMarkerWidth;
                left: 0;
                position: absolute;
                width: 100%;
              }
            }

            @if ($useLeftBorderMarker == true) {
              border-left: solid $borderMarkerWidth $borderMarkerColor;

              padding-left: nth(
                $itemPadding,
                4
              ); /*50px; nth($itemPadding, 4) - $borderMarkerWidth;*/

              [dir='rtl'] & {
                border-left: none;
                border-right: solid $borderMarkerWidth $borderMarkerColor;

                padding-left: nth($itemPadding, 2);
                padding-right: nth($itemPadding, 4);
              }
            }
          }
        }

        &--has-children {
          & > a {
            position: relative;
            @include icon($itemHasChildrenIcon);

            &:before {
              color: $hasChildrenIconColor;
              font-size: 30px;
              line-height: 1;
              margin-left: 0;
              margin-right: 0;
              position: absolute;
              right: 12px;
              top: 50%;
              transform: translateY(-50%);

              [dir='rtl'] & {
                left: 12px;
                right: auto;
              }
            }
          }

          &.c-localnav__list__item--is-selected,
          &.c-localnav__list__item--is-open {
            & > a {
              @include icon($itemHasChildrenIcon);

              &:before {
                transform: translateY(-50%) rotate(90deg);
                transform-origin: center center;
              }

              @if ($useLeftBorderMarker == true) {
                border-left: solid
                  $borderMarkerWidth
                  rgba($borderMarkerColor, 0.15);

                &:hover,
                &:focus {
                  border-left: solid $borderMarkerWidth $borderMarkerColor;
                }

                [dir='rtl'] & {
                  border-left: none;
                  border-right: solid
                    $borderMarkerWidth
                    rgba($borderMarkerColor, 0.15);

                  &:hover,
                  &:focus {
                    border-left: none;
                    border-right: solid $borderMarkerWidth $borderMarkerColor;
                  }
                }
              }

              &:before {
                line-height: 1;
                margin-left: 0;
                margin-right: 0;
              }
            }
          }

          &.c-localnav__list__item--is-selected {
            & > a {
              font-weight: $selectedItemFontWeight;

              @if ($useLeftBorderMarker == true) {
                border-left: solid $borderMarkerWidth $borderMarkerColor;

                [dir='rtl'] & {
                  border-left: none;
                  border-right: solid $borderMarkerWidth $borderMarkerColor;
                }
              }
            }
          }
        }

        &--is-open {
          a {
            @if ($useLeftBorderMarker == true) {
              border-left: solid
                $borderMarkerWidth
                rgba($borderMarkerColor, 0.15);

              [dir='rtl'] & {
                border-left: none;
                border-right: solid
                  $borderMarkerWidth
                  rgba($borderMarkerColor, 0.15);
              }
            }
          }
        }
        &--is-selected {
          & > a {
            font-weight: $selectedItemFontWeight;
            background: $selectedItemBg;

            @if ($useLeftBorderMarker == true) {
              border-left: solid $borderMarkerWidth $borderMarkerColor;

              [dir='rtl'] & {
                border-left: none;
                border-right: solid $borderMarkerWidth $borderMarkerColor;
              }
            }

            span {
              color: $selectedItemColor;
            }
          }
        }

        //indent
        .c-localnav__list__item {
          a {
            padding-left: 30px;

            [dir='rtl'] & {
              padding-left: 20px;
              padding-right: 30px;
            }

            &:hover,
            &:focus {
              border-color: $borderMarkerColor;
              @if ($useLeftBorderMarker == true) {
                padding-left: 30px;

                [dir='rtl'] & {
                  padding-left: 20px;
                  padding-right: 30px;
                }
              }
            }
          }

          .c-localnav__list__item {
            a {
              padding-left: 60px;

              [dir='rtl'] & {
                padding-left: 20px;
                padding-right: 60px;
              }

              &:hover,
              &:focus {
                @if ($useLeftBorderMarker == true) {
                  padding-left: 60px;

                  [dir='rtl'] & {
                    padding-left: 20px;
                    padding-right: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @content;
  }
}
