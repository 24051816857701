@mixin CookieProPanel(
  $focusColor: black,
  $focusOffset: 0px,
  $focusBtnOffset: 4px,
  $acceptBtnHoverColor: black,
  $acceptBtnTextHoverColor: inherit,
  $settingsBtnTextHoverColor: inherit,
  $settingsBtnHoverColor: white,
  $fontWeight: normal,
  $settingsBtnBorder: 1px solid black,
  $textTransform: uppercase,
  $settingsBtnFontHoverColor: inherit,
  $btnBorderRadius: 30px,
  $btnFont: inherit,
  $btnFontSize: rem-calc(14),
  $btnSize: 50px
) {
  #onetrust-banner-sdk:focus {
    outline: 2px solid $focusColor !important;
  }

  #onetrust-banner-sdk,
  #onetrust-pc-sdk {
    #ot-pc-content > div#ot-pc-desc,
    p.ot-category-desc,
    p#onetrust-policy-text {
      font-size: rem-calc(16);
      padding-left: 0px;
    }

    a {
      &:focus {
        outline: 2px solid $focusColor;
        outline-offset: $focusOffset;
      }
      &:hover {
        text-decoration: underline;
      }
    }

    #ot-pc-desc,
    #onetrust-policy {
      padding: 0 6px;
    }

    div#onetrust-button-group,
    div#ot-pc-content,
    div.ot-pc-footer > div.ot-btn-container {
      padding-left: 6px;
      button {
        border-radius: $btnBorderRadius;
        text-transform: $textTransform;
        font-family: $btnFont;
        font-size: $btnFontSize;
        letter-spacing: normal;
        font-weight: $fontWeight;
        height: $btnSize;
        margin-right: 10px;

        &:focus {
          outline: 2px solid $focusColor;
          outline-offset: $focusBtnOffset;
          opacity: 1;
        }
        &:hover {
          opacity: 1;
          color: $acceptBtnTextHoverColor !important;
          background-color: $acceptBtnHoverColor !important;
        }
      }

      #onetrust-accept-btn-handler,
      #onetrust-pc-sdk > div#ot-pc-content > button {
        padding: 10px 20px;
        text-decoration: none;
        font-family: $btnFont;
        font-size: $btnFontSize;
        letter-spacing: normal;
        font-weight: $fontWeight;
        &:hover {
          /** Onetrust uses opacity on hover**/
          opacity: 1;
          color: $acceptBtnTextHoverColor !important;
          background-color: $acceptBtnHoverColor !important;
        }
      }
      #onetrust-pc-btn-handler {
        text-decoration: none;
        padding: 10px 20px;
        border: $settingsBtnBorder;
        &:hover {
          color: $settingsBtnTextHoverColor !important;
          background-color: $settingsBtnHoverColor !important;
        }
      }

      .ot-hlst-cntr > button.ot-link-btn.category-host-list-handler {
        background-color: white;
        text-decoration: underline;
        font-family: unset;
        font-size: rem-calc(13);
        text-transform: unset;
        font-weight: unset;
        padding: 1px;
        height: unset;
        &:hover {
          background-color: white !important;
          color: unset !important;
          text-decoration: none;
        }
      }
    }
    @media print {
      display: none;
    }

    @content;
  }
}
