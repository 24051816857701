@import '../../platformui/Forms/styles';

@include Select(
  $bg: c('sky-background'),
  $border: 1px solid c('sky-base'),
  $borderRadius: 10px,
  $color: c('black'),
  $disabledBg: c('white'),
  $disabledBorder: solid 1px c('stone-clear'),
  $fadeGradient: none,
  $fadeGradientError: none,
  $focusBorder: solid 1px c('sky-base'),
  $focusShadow: inset 2px 6px 6px 0 rgba(c('stone-base'), 0.15),
  $optionHoverBg: c('sky-base'),
  $optionHoverBgDisabled: #def0f7,
  $scrollBarBg: c('sky-base')
) {
    &__select {
      select {
        cursor: pointer;
        font-size: rem-calc(16);
        font-weight: bold;

        @media print {
          border-color: black;
        }
      }
      &:before {
        color: c('sky-base');
        font-weight: bold;
      }
    }
  }

@include Error(
  $color: c('main'),
  $errorBg: c('secondary-light'),
  $errorBorder: dashed 1px c('main'),
  $errorFocusBorder: dashed 1px c('main'),
  $errorFocusShadow: inset 2px 6px 6px 0 rgba(c('main'), 0.2)
);

.c-select {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px; 
    gap: 5px;

    &__label {
      font-size: 0.875rem;
    }    
    select {
      font-size: 1rem;
    }
}