.c-linklist {
  display: flex;
  width: 100%;

  &__list {
    width: 100%;
  }

  &.c-linklist--site-footer {
    flex-direction: column;
    column-gap: 50px;

    @include breakpoint(large) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .c-linklist {
      width: auto;

      @include breakpoint(large) {
        justify-content: flex-end;
      }

      ul {
        display: flex;
        flex-direction: column;

        @include breakpoint(large) {
          column-gap: 50px;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      li {
        margin: 8px 0;
      }
    }

    @include breakpoint(medium) {

      li,
      a {
        display: inline-block;
      }
    }

    .c-linklist__link {
      font-size: 0.875rem;
      color: c('nav');
      text-align: left;

      @include breakpoint(large) {
        color: c('white');
      }

      padding-left: 0;
      text-decoration: underline;

      @include breakpoint(large) {
        margin-right: 0;
        @include focus-handler($color: c('white'));
      }

      span {
        color: inherit;
      }
    }
  }

  &.footer-linklist {
    li {
      padding: 0 0 10px 0;

      a {
        color: c('main');
        @include focus-handler($color: c('nav'));

        span {
          color: c('nav');
        }
      }
    }

    @include LinklistLink($icon: 'f105') {
      &:before {
        color: c('main');
      }

      &[href$="/"],
      &[href*="http://"],
      &[href*="https://"] {
        &:before {
          font-size: 19px;
          top: 3px;
        }
      }

      span {
        color: c('nav');
        text-decoration: underline;

        &:hover {
          color: c('nav');
        }
      }
    }
  }

  // Will not show arrow icon in mobile view
  &.c-linklist--no-icons {
    a {
      &:before {
        display: none;
        content: none;
      }
    }
  }
}
