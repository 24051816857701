@import '../../platformui/SubjectIntro/styles';

@include SubjectIntro($backgroundColor: c('sky-background'),
  $preambleHoverColor: c('sky-dark'),
  $subjectColor: c('stone-dark'),
  $subjectTextColor: c('stone-dark'),
  $linkListColor: c('sky-base'),
  $linkListHoverColor: c('sky-base'),
  $imageMaxWith: 1020px,
  $height: 430px,
  $backgroundImage: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgwIiBoZWlnaHQ9IjMxNiIgdmlld0JveD0iMCAwIDI4MCAzMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwQzAgMCA3My4zMjEzIDguMjk5MjUgMTQyLjIxNiA4LjI5OTI1QzIxMS4xMSA4LjI5OTI1IDI4MCAwIDI4MCAwVjMxNkgwVjBaIiBmaWxsPSIjRjFGMUY1Ii8+Cjwvc3ZnPgo=')) {}

.subject-intro {
  z-index: 0;

  @include breakpoint(large) {
    margin-bottom: 50px;

    &::after {
      content: none;
    }
  }

  &>.row.expanded {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  .large-7 {
    float: right;
  }

  &__image-container {
    &:after {
      content: none;
    }

    @include breakpoint(medium) {
      height: 430px;
    }

    @include breakpoint(large) {
      &:before {
        content: none;
      }
    }

    &__wrapper {
      background-color: transparent;
    }

    &>div .c-image {
      border-radius: 10px 10px 0 0;

      @include breakpoint(large) {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  &__subject-heading {
    margin: 20px 0 10px;

    @include breakpoint(medium) {
      margin: 50px 0 10px;
    }

    @include breakpoint(large) {
      margin: 0 0 10px;
      font-size: 2.25rem;
      line-height: 36px;
    }

    font-size: 1.625rem;
    line-height: 26px;
  }

  &__subject-heading-vardpersonal {
    margin: 20px 0 10px;

    @include breakpoint(medium) {
      margin: 50px 0 10px;
    }

    @include breakpoint(large) {
      margin: 0 0 10px;
      font-size: 2.25rem;
      line-height: 46px;
    }

    font-size: 1.625rem;
    line-height: 26px;
  }

  &__preamble {

    p,
    a {
      font-size: 1rem;
      line-height: 26px;

      @include breakpoint(large) {
        font-size: 1.125rem;
      }
    }
  }

  &__preamble-vardpersonal {
    margin-bottom: 30px;

    p,
    a {
      margin-bottom: 0px;
      font-size: 1rem;
      line-height: 26px;

      @include breakpoint(large) {
        font-size: 1.125rem;
      }
    }
  }

  &__text {
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 0 0 10px 10px;

    &:after {
      background-size: 300vw;
      background-position: top;
      background-color: transparent;
      left: 0;
      right: 0;
      top: -20px;
      height: 100%;
      z-index: 1;
    }

    @include breakpoint(medium) {
      top: -40px;
    }

    @include breakpoint(large) {
      border-radius: 10px 0 0 10px;
      padding-bottom: 30px;
      top: 0;

      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQ4IiBoZWlnaHQ9IjQzMCIgdmlld0JveD0iMCAwIDY0OCA0MzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02NDcuMjcyIDUuNjE3OGUtMDVDNjI3LjI5NyA2OC43MjIgNjE2LjYyNSAxNDEuMTEyIDYxNi42MjUgMjE1Ljg2OUM2MTYuNjI1IDI4OS45OTYgNjI3LjExOCAzNjEuNzk2IDY0Ni43NjkgNDMwTDAgNDMwTDMuNzg2NWUtMDUgMEw2NDcuMjcyIDUuNjE3OGUtMDVaIiBmaWxsPSIjRjFGMUY1Ii8+Cjwvc3ZnPgo=');
        background-position: right;
        background-size: cover;
        top: 0;
        left: 100px;
      }
    }

    &__content {
      margin: 0 auto;
      z-index: 1;

      @include breakpoint(large) {
        padding: 15px 51px 0 0;
        margin-left: 90px;
        max-width: 450px;
      }

      @include breakpoint(xlarge) {
        padding-right: 20px;
      }
    }

    .row.expanded {
      min-width: 100%;
      z-index: 3;
    }
  }

  &-linklist {
    &__link {
      text-align: center;
      width: 100%;
      border-radius: 30px;

      font-weight: normal;

      &:focus {
        background-color: c('sky-base');
      }

      span {
        @include secondary-font-family();
      }
    }
  }

  &-linklist-vardpersonal {
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include breakpoint(large) {
        align-items: flex-start;
      }
    }

    li {
      margin-right: 0px;

      a {
        display: flex;
        color: white;
      }
    }

    &__link {
      text-align: center;
      width: 100%;
      border-radius: 30px;
      padding: 0px 30px;
      font-weight: normal;

      &:hover {
        background-color: c('sky-dark');
      }

      &:focus {
        background-color: c('sky-base');
      }

      span {
        @include secondary-font-family();
        font-size: 16px;
      }
    }
  }
}
