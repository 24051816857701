@mixin AOList(
  $backgroundColor: #fff,
  $backgroundColorSelected: (
    #fff,
    #fff,
  ),
  $backgroundColorActive: (
    #fff,
    #fff,
  ),
  $backgroundColorInactive: (
    #fff,
    #fff,
  ),
  $border: 1px solid #000,
  $borderSelected: 1px solid #ccc,
  $borderInactive: none,
  $className: 'c-anchorlinks',
  $color: #000,
  $colorInactive: #000,
  $colorSelected: (
    #000,
    #000,
  ),
  $colorActive: (
    #fff,
    #fff,
  ),
  $fontWeight: (
    normal,
    normal,
  ),
  $fontWeightSelected: (
    normal,
    normal,
  ),
  $margin: (
    20px 0 0,
    0 auto 30px,
  ),
  $maxWidth: false,
  $fontSize: (
    rem-calc(12),
    rem-calc(12),
  ),
  $fontSizeSelected: (
    rem-calc(12),
    rem-calc(12),
  ),
  $heightItem: 40px,
  $itemPadding: (
    4px 0 4px 16px,
    9px 0,
  ),
  $itemPaddingSelected: (
    2px 5px 2px 15px,
    9px 0,
  ),
  $textAlign: center,
  $textTransform: uppercase,
  $transformScale: 1.3,
  $padding: (
    30px 0 0,
    0,
  ),
  $width: (
    60px,
    100%,
  ),
  $widthItem: 40px
) {
  .#{$className} {
    display: block;
    list-style: none;
    margin: nth($margin, 1);
    padding: nth($padding, 1);
    position: absolute;
    width: nth($width, 1);

    @include breakpoint(medium) {
      margin: nth($margin, 2);
      @if ($maxWidth != false) {
        max-width: $maxWidth;
      }
      padding: nth($padding, 2);
      position: relative;
      text-align: $textAlign;
      width: nth($width, 2);
    }

    li {
      margin: 0;
      display: block;

      @include breakpoint(medium) {
        display: inline-block;
      }

      span {
        color: $colorInactive;
        border: $borderInactive;
      }
    }

    &__item {
      font-size: nth($fontSize, 1);
      font-weight: nth($fontWeight, 1);
      display: none;
      padding: nth($itemPaddingSelected, 1);
      position: relative;
      text-align: left;
      text-transform: $textTransform;
      text-decoration: none;
      transition: all 0.2s ease;

      @include breakpoint(small only) {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center !important;
        padding: 2px 5px 2px 15px !important;
      }

      &:focus,
      &:active,
      &:target {
        background: nth($backgroundColorActive, 1);
        border-color: nth($backgroundColorActive, 1);
        color: nth($colorActive, 1);
      }

      @include breakpoint(medium) {
        background: nth($backgroundColorInactive, 2);
        border: $border;
        border-radius: 100px;
        display: inline-block;
        font-size: nth($fontSize, 2);
        font-weight: nth($fontWeight, 2);
        height: $heightItem;
        margin: 0 rem-calc(10) rem-calc(10) 0;
        padding: nth($itemPadding, 2);
        text-align: center;
        width: $widthItem;
      }

      &--matched {
        color: $color;
        display: block;

        @include breakpoint(medium) {
          background: nth($backgroundColor, 2);
          display: inline-block;
          transform: scale(1);

          &:active,
          &:target,
          &:focus,
          &:hover {
            outline: none;
            text-decoration: none;
            transform: scale($transformScale);
          }
        }
      }

      &--selected {
        background: nth($backgroundColorSelected, 1);
        border-bottom-left-radius: 100px;
        border-top-left-radius: 100px;

        @if (nth($colorSelected, 1) != false) {
          color: nth($colorSelected, 1);
        }

        font-size: nth($fontSizeSelected, 1);
        font-weight: nth($fontWeightSelected, 1);
        padding: nth($itemPaddingSelected, 1);
        text-decoration: none;

        @include breakpoint(medium) {
          @if (nth($colorSelected, 2) != false) {
            color: nth($colorSelected, 2);
          }

          font-size: nth($fontSizeSelected, 2);
          font-weight: nth($fontWeightSelected, 2);
          padding: nth($itemPaddingSelected, 2);
        }
      }
    }

    @content;
  }
}
