@import '../../platformui/AOListBlock/styles.scss';

@include AOList(
  $backgroundColor: (
    $white,
    c('sky-background'),
  ),
  $backgroundColorActive: (
    c('denim'),
    c('denim'),
  ),
  $backgroundColorInactive: (
    c('white'),
    c('white'),
  ),
  $backgroundColorSelected: (
    c('sky-background'),
    c('white'),
  ),
  $border: 1px solid c('sky-base'),
  $borderInactive: 1px solid c('sky-line'),
  $color: c('denim'),
  $colorInactive: c('stone-base'),
  $colorSelected: (
    c('black'),
    c('denim'),
  ),
  $colorActive: (
    c('white'),
    c('white'),
  ),
  $fontSize: (
    0.625rem,
    1.25rem,
  ),
  $fontSizeSelected: (
    1rem,
    1.25rem,
  ),
  $fontWeight: (
    normal,
    normal,
  ),
  $fontWeightSelected: (
    bold,
    normal,
  ),
  $heightItem: 30px,
  $margin: (
    0,
    0 auto,
  ),
  $padding: (
    0,
    20px 0,
  ),
  $textAlign: left,
  $transformScale: 1.1,
  $width: (
    40px,
    100%,
  ),
  $widthItem: 30px,
  $itemPadding: (
    0,
    0,
  ),
  $itemPaddingSelected: (
    0,
    0,
  )
) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 10px;
  align-items: center;

  &__item {
    @include secondary-font-family();

    @include breakpoint(medium) {
      margin: 0;
    }
  }
} ;
