@import '../../platformui/Modal/tooltip-modal.scss';

@include TooltipModal(
  $className: 'diagnostic-panel',
  $buttonFloat: none,
  $useFadeOut: false,
  $modalPositionTop: 140px,
  $modalCloseBtnColor: c('black'),
  $modalBtnColorOpen: c('white'),
  $focusColor: c('black')
) {
  &__modal-open {
    display: block;
    height: 0px;

    span {
      &:before {
        font-size: 30px;
        position: absolute;
        top: 15px;
        right: 0;
        margin-right: 15px;
      }
    }
    &:hover,
    &:focus {
      span {
        &:before {
          top: 15px;
          margin-right: 15px;
        }
      }
    }

    &--open {
      &:after {
        top: 34px;
        right: 18px;
      }
    }
  }

  &__modal-panel {
    height: auto;
    max-width: 300px;
    right: -10px;
    top: 58px;

    @include breakpoint(medium) {
      right: -120px;
    }
  }
}
