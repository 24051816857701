@import '../../platformui/Search/globalsearch.scss';
@import '../../platformui/SearchBlock/styles';
@import '../../platformui/Framework/platform-mixins/HeaderToolsPanel';

@include GlobalSearch() {
  @include HeaderToolsPanel(
    $className: c-globalsearch,
    $panelHeights: (
      small: 80px,
      medium: 86px,
      large: 120px,
    ),
    $shadow: none
  ) {
    .c-globalsearch {
      z-index: 67;
      box-shadow: 0 2px 4px 0 c('stone-line');
      .icon-search {
        top: 6px;
        left: 10px;

        &:before {
          color: c('sky-dark');
          @include breakpoint(small only) {
            content: none;
          }

          @include breakpoint(medium only) {
            content: none;
          }
        }
      }

      &__form-component {
        padding-right: 0px;

        .c-globalsearch__field-container {
          width: calc(100% - 69px);

          @include breakpoint(large) {
            width: calc(100% - 130px);
          }
        }

        @include breakpoint(large) {
          padding-right: 0px;
        }
      }

      &__inner {
        padding: 20px 5px;

        @include breakpoint(medium) {
          padding: 23px 20px;
        }

        @include breakpoint(large) {
          padding: 35px 0;
        }
      }
    }
  }

  @include SearchField(
    $className: c-globalsearch,
    $searchFieldLabel: c('sky-dark'),
    $buttonBg: c('sky-base'),
    $buttonColor: $white,
    $buttonBorderRadius: (
      0 10px 10px 0,
      10px,
    ),
    $buttonWidthLarge: 110px,
    $buttonHoverBg: c('sky-base')
  ) {
    .c-globalsearch__field {
      &__button {
        @include focus-handler();
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: bold;
        height: 50px;

        @include breakpoint(large) {
          font-size: 20px;
          height: 60px;
        }
      }
    }
  }

  @include SearchFieldQuery(
    $className: c-globalsearch,
    $searchFieldBg: c('sky-background'),
    $borderRadius: (
      10px 0 0 10px,
      10px,
    ),
    $searchFieldFocusBorder: 1px solid c('black')
  ) {
    .c-globalsearch__field__query {
      font-size: 1rem;
      color: c('stone-dark');
      height: 50px;
      padding-left: 20px;
      border: 1px solid c('sky-base');
      @include base-font-family();
      @include focus-handler();

      @include breakpoint(large) {
        padding-left: 56px;
        font-size: 1.125rem;
        height: 60px;
      }

      &::placeholder {
        color: c('stone-base');
        font-style: italic;
      }
    }
  }
}
