@import '../../platformui/Buttons/button';

.checkbox--checked {
  @include icon('E85A');
  color: c('sky-base');
  font-size: 26px;

  &:before {
    position: absolute;
    left: -4px;
    top: -3px;
    margin-right: 0;
  }
}


.shadow-bg {
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.information-modal {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 auto;
  padding: 30px 20px 50px 20px;
  width: 100%;
  max-width: 700px;
  background-color: c('white');
  color: c('black');
  opacity: 1;
  border-color: transparent;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(c('black'), 0.5);

  @include breakpoint(large) {
    padding: 50px 40px;
  }

  &__content {
    display: grid;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    min-height: 234px;

    &__title {
      line-height: 46px;
      margin-block-end: 20px;
    }

    &__text {
      width: 100%;
      margin-block-end: 20px;

      p {
        margin-bottom: 0;
        line-height: 24px;
      }

      a {
        text-decoration: underline;
        color: c('sky-base');
      }
    }

    &__checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-block: 20px 30px;

      &__button {
        @include focus-handler();
        position: relative;
        border: 1px solid c('sky-base');
        background-color: c('sky-background');
        border-radius: 4px;
        padding: 0px;
        height: 20px;
        width: 20px;
        height: 20px;
        margin-inline-end: 8px;
      }

      &span {
        pointer-events: none;
        user-select: none;
      }
    }
  }

  @include button($bg: c('sky-base'),
    $border: solid 1px c('sky-base'),
    $color: c('white'),
    $hoverBg: c('sky-dark'),
    $hoverColor: c('white'),
    $className: 'information-modal__content__button',
  ) {
    justify-self: center;
    align-self: end;
    font-weight: 700;
    padding: 14px 30px;
    width: 100%;
    height: 50px;

    @include breakpoint(large) {
      width: fit-content;
    }

    &:hover,
    &:focus {
      @include focus-handler();
    }
  }
}
