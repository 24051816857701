@mixin ExpandButton(
  $className: 'c-expand-button',
  $expandedClassName: '--is-open',
  $expandedClassModifier: 'set-class-if-necessary',
  $topPosition: 0,
  $bgColorBtn: transparent,
  $btnHeight: 100%,
  $btnWidth: 40px,
  $btnBorderRadius: 0,
  $plusMinusColor: $white,
  $lineBorderRadius: 0,
  $lineThickness: 2px,
  $lineSize: 16px,
  $marginCentered: -8px
) {
  .#{$className} {
    position: absolute;
    background: $bgColorBtn;
    top: $topPosition;
    right: 0;
    height: $btnHeight;
    width: $btnWidth;
    border-radius: $btnBorderRadius;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    z-index: 2;
    color: transparent;

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: $plusMinusColor;
      transition: transform 0.25s ease-out;
      color: $plusMinusColor;
      border-radius: $lineBorderRadius;
    }

    /* vertical line */
    &:before {
      top: 50%;
      left: 50%;
      width: $lineThickness;
      height: $lineSize;
      margin-left: -1px;
      margin-top: $marginCentered;
    }

    /* horizontal line */
    &:after {
      top: 50%;
      left: 50%;
      width: $lineSize;
      height: $lineThickness;
      margin-left: $marginCentered;
      margin-top: -1px;

      [dir='rtl'] & {
        margin-left: 0;
      }
    }

    /* animation on vertical and horizontal lines */
    &#{$expandedClassName} {
      &:before {
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(180deg);
      }
    }

    #{$expandedClassModifier} & {
      &:before {
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(180deg);
      }
    }
    @content;
  }
}
