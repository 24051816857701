@mixin HeaderToolsPanel(
  $className: set-class,
  $panelHeights: (
    small: 70px,
    medium: 76px,
    large: 100px,
  ),
  $borderRadius: 0,
  $borderRadiusLarge: 0,
  $maxWidth: none,
  $shadow: drop-shadow(0 4px 6px rgba($black, 0.3))
) {
  .#{$className} {
    background-color: $white;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    bottom: 0;
    left: 0;
    height: map-get($panelHeights, small);
    margin: 0 auto;
    max-width: $maxWidth;
    top: 0;
    transform: translateY(-100%);
    transition: all 0.4s ease;
    position: absolute;
    right: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;

    @include breakpoint(medium) {
      height: map-get($panelHeights, medium);
    }

    @include breakpoint(large) {
      border-bottom-right-radius: $borderRadiusLarge;
      border-bottom-left-radius: $borderRadiusLarge;
      height: map_get($panelHeights, large);
    }

    &--is-opened {
      filter: $shadow;
      z-index: 200;
      opacity: 1;
      visibility: visible;
    }

    &__inner {
      padding: 15px 0;

      @include breakpoint(medium) {
        padding: 18px 0;
      }

      @include breakpoint(large) {
        padding: 25px 20px;
      }
    }
  }
  @content;
}
