@import '../../platformui/InfoBannerVardpersonal/styles.scss';

@include InfoBannerVardpersonal(
  $backgroundColor: c('stone'),
  $borderRadius: 5px,
  $btnFontColor: c('sky-base'),
  $modalLinkColor: c('stone-dark')
) {
  .info-banner-vardpersonal {
    border-top-width: 6px;
    border-top-style: solid;
    border-top-color: c('sky-base');
    position: sticky;
    z-index: 2;
    transition: all 0.3s linear;

    @include breakpoint(large) {
      max-height: 50px;
    }

    &__first-item {
      align-self: center;
    }

    &__type {
      &--search-page,
      &--vardforlopp {
        .info-banner-vardpersonal {
          @include breakpoint(medium, small) {
            &__content {
              padding: 14px 20px 14px 20px;
            }

            &__second-item {
              align-self: center;
            }

            &__first-item {
              justify-self: flex-start;
            }

            &__second-item button {
              font-size: 14px;
              margin: 0;
              &::after {
                margin: 0 0 4px 5px;
              }
            }
          }
        }
      }
    }

    &__subject {
      @include secondary-font-family();
      letter-spacing: -0.4px;

      &__indicator{
        width: 9px;
        height: 25px;
        margin-right: 10px;
      }
    }

    &__title {
      @include breakpoint(large) {
        border-left: 1px solid c('stone-base');
        padding-left: 10px;
      }
    }

    .c-listselect__list__item__link__tooltip {
      top: 60px;
    }

    .kunskapsstod-color {
      background: c('main');
    }

    .vardforlopp-color {
      background: c('sky-clear');
    }

    .vardprogram-color {
      background: c('grass-base');
    }

    .vardriktlinjer-color {
      background: c('sun-clear');
    }
  }
}
