@import '../../platformui/ArticleFooter/styles';

@include ArticleFooter($background: c('white'), $padding: 0) {
  .c-articlefooter {
    color: c('stone-dark');

    margin: 90px 0 30px 0;

    .c-articlefooter__wrapper {
      position: relative;
      border-top: 1px solid c('stone-clear');
      padding-top: 20px;
    }

    @include breakpoint(large) {
      margin: 20px 0 30px 0;
    }

    &__key {
      font-weight: bold;
      display: block;
    }

    @media print {
      font-size: 8px !important;
    }
  }
}
