@mixin CookieProSettings(
  $color: hotpink,
  $secondaryColor: $color,
  $focusColor: hotpink,
  $secondaryFocusColor: $focusColor,
  $fontSize: unset,
  $secondaryFontSize: $fontSize
) {
  .ot-container {
    button#ot-sdk-btn.ot-sdk-show-settings {
      border: none;
      color: $color;
      font-size: $fontSize;
      line-height: 1.3;
      padding: 0;
      text-decoration: underline;
      transition: none;
      height: 48px;
      width: 100%;
      text-align: left;
      [dir='rtl'] & {
        text-align: right;
      }
      &:before {
        content: none !important;
      }
      &:hover {
        background: transparent;
        text-decoration: underline;
      }

      @include focus-handler($color: $focusColor, $offset: 4px);
      @include breakpoint(large) {
        font-size: $secondaryFontSize;
        color: $secondaryColor;
        @include focus-handler($color: $secondaryFocusColor, $offset: 4px);
      }
    }
  }
}
