@import '../../platformui/ListSelect/styles.scss';
@include ListSelect(
  $listBorderBottom: 1px solid c('sky-line'),
  $activeItemBorderBottom: 7px solid c('sky-base'),
  $activeItemBackground: c('white'),
  $tooltipBackgroundColor: c('white'),
  $tooltipColor: c('black'),
  $tooltipBoxShadow: 0px 0px 10px 0px rgba(c('black'), 0.3),
) {
  width: 100%;
  justify-content: center;

  @include breakpoint(medium) {
    width: auto;
    justify-content: flex-start;
  }

  &__label {
    text-transform: none;
  }

  &__list {
    width: 100%;
    justify-content: space-evenly;

    &__item {
      &__link {
        &--active {
          border-radius: 5px 5px 0px 0px;
          padding: 0px 5px;
        }
        
        &--inactive {
          color: c('stone-clear');
          font-style: italic;
        }
      }
    }

    @include breakpoint(medium) {
      width: auto;
    }
  }
}
