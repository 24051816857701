@mixin search-list-item(
  $className: 'c-related',
  $containerPadding: 10px 30px 10px 56px,
  $itemBgColor: false,
  $itemAlternatingBgColor: false,
  $itemBorderRadius: 0
) {
  .#{$className} {
    li {
      list-style: none;
      margin-bottom: 0;

      @if $itemBgColor {
        .#{$className}__item {
          background: $itemBgColor;
        }
      }

      @if $itemAlternatingBgColor {
        &:nth-child(odd) {
          .#{$className}__item {
            background: $itemAlternatingBgColor;
          }
        }
      }
    }

    &__items {
      padding-bottom: 10px;
    }

    &__item {
      border-radius: $itemBorderRadius;
      display: block;

      &__container {
        padding: $containerPadding;
        position: relative;
        z-index: 0;

        &--no-z-index {
          z-index: auto;
        }
      }

      &__heading {
        margin-bottom: 3px;

        em {
          font-weight: 500;
          font-style: inherit;
        }
      }

      &__text {
        margin-bottom: 0;
        max-width: 720px;

        em {
          font-weight: bold;
          font-style: inherit;
        }
      }
    }
    @content;
  }
}
